//$tabTrigger relies on profile-address event, it is needed only for cart invoice form, but may be needed for others as well
import './profile-address'

export class AddressSubform {
    settings = {
        addressTypeSelector: '.address-subform__type',
        requiredFormFieldsSelector: 'input[data-validity-required]',
        toggleFormSelector: '.address-subform__toggle',
        toggleForm: false,
    }

    static init ($elements, settings) {
        //each element should have it's own instance to allow modifying/accessing settings depending on context
        $.each($elements, (i, e) => e.nestedNewAddressForm = new AddressSubform($(e), settings))
    }

    constructor ($container, settings) {
        if (typeof settings === 'undefined') {
            settings = {};
        }
        this.settings = $.extend(this.settings, settings)

        this.registerAddressTypeChangeEvent($container)

        if (this.settings.toggleForm) {
            this.registerToggleFormOnCheckEvent($container)
        } else {
            this.showSelectedAddress($container)
        }
    }

    showSelectedAddress($container) {
        const $navLinks = $container.find('.nav-link');
        const addressTypeVal = $container.find(this.settings.addressTypeSelector).val();
        let $tabTrigger = $navLinks.first();

        if (addressTypeVal) {
            $tabTrigger = $navLinks.filter((i,e) => $(e).data('id') === addressTypeVal).first();
            if ($tabTrigger.length === 0) {
                $tabTrigger = $navLinks.first();
            }
        }

        $tabTrigger.trigger('click')
    }

    clearSelectedAddressType($container) {
        //turns off validation for all subforms
        $container.find('.address__form ' + this.settings.requiredFormFieldsSelector).removeAttr('required')
        $container.find(this.settings.addressTypeSelector).val('')
    }

    registerAddressTypeChangeEvent($container) {
        const self = this
        $container.on('click', '.nav-link', function (e) {
            const $this = $(this);
            const id = $this.data('id');
            const $container = $this.closest('.address-form__tabs')

            $(e.delegateTarget).find(self.settings.addressTypeSelector).val(id)
            //Enables validation of only displayed form
            $container.find('.address__form ' + self.settings.requiredFormFieldsSelector).removeAttr('required')
            $('.address-form--' + id).find(self.settings.requiredFormFieldsSelector).attr('required', 'required')
        });
    }

    registerToggleFormOnCheckEvent($container) {
        const self = this;
        $container.on('change', this.settings.toggleFormSelector, function (e) {
            const $toggleContainer = $(e.delegateTarget)
            if (!$(this).is(':checked')) {
                self.clearSelectedAddressType($toggleContainer)
            } else {
                self.showSelectedAddress($toggleContainer)
            }
        })

        $(this.settings.toggleFormSelector).trigger('change');
    }
}