import {CustomValidator} from "./validator";

jQuery(document).on('blur', '.password-validator', function (e) {
    e.preventDefault();
    const $this = $(this);

    if ($this.val() === '') {
        return;
    }

    const url = $this.data('password-validator');

    if (!url) {
        return;
    }

    const $form = $this.closest('form');
    const that = this;

    $.ajax({
        url,
        data: {password: $this.val()},
        method: 'POST',
        success: function ({valid}) {
            $this.trigger('password-validated', valid)
            if (!valid) {
                CustomValidator.markInvalid(that);
            }
        },
        error: function () {
            $this.trigger('password-validated', false)
            CustomValidator.markInvalid(that);
            $form.trigger('interrupted.submit', {submitter: e.submitter});
            $form.trigger(jQuery.Event('form.invalid'));
        },
    });
});

document.addEventListener('hide.bs.modal', function (e) {
    $(e.target)
        .find('.password-validator')
        .val('')
        .removeClass('is-valid is-invalid')
        .closest('form')
        .removeClass('was-validated')
        .addClass('needs-validation');
});
