import {Spinner} from './spinner'
import {Toast} from "../../components/toast/js/toast";

jQuery(document).on('click', '.submit-captcha', function (e) {

    const $this = $(this);
    const $form = $this.closest('form');

    if ($form.length === 0) {
        return;
    }

    const $inputCaptcha = $form.find('.g-recaptcha-custom');

    if ($inputCaptcha.length === 0) {
        return;
    }

    const siteKey = $inputCaptcha.data('siteKey');

    if(typeof siteKey !== 'string' || siteKey.length === 0 ) {
        return;
    }

    e.preventDefault();

    const spinner = new Spinner();
    spinner.addSpinner($this);
    $this.attr('disabled', 'disabled');

    grecaptcha.ready(function () {
        grecaptcha.execute(siteKey)
            .then(function (token) {

                $inputCaptcha.val(token);
                $form.trigger("submit");
            })
            .catch(function (err) {
                $this.removeAttr('disabled');
                spinner.removeSpinner($this);
                new Toast('error', translations['error-message']);
            });
    });
});