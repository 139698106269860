import {Toast} from "../../components/toast/js/toast";


export async function updateCustomerAgreement(url, value) {
    if (!url) {
        return Promise.reject();
    }

    return new Promise((resolve, reject) => $.post(url, {value}).then(resolve).catch(reject));
}

export async function updateCustomerAgreementWithToast(url, value, message) {
    return updateCustomerAgreement(url, value).then(() => message && new Toast('success', message));
}

$(document).on('click', '.customer-agreement', function () {
    const $this = $(this);
    updateCustomerAgreementWithToast(
        $this.data('url'),
        $this.prop('checked'),
        $this.data('message'),
    ).catch(() => null);
});
