import 'selectizeJs'
import PerfectScrollbar from 'perfect-scrollbar'

window.selectize_options = {
    onInitialize() {
        this.$control.off('click');
        this.$control_input.addClass('selectize-input__search')
        !this.$input.data('enable-search') && this.$control_input.attr('readonly', true)
        typeof this.$input.data('input-class') === 'string' && this.$control.addClass(this.$input.data('input-class'))
    },
    onDelete() {
        this.$input.data('deleted-selection', this.getValue())
    },
    onBlur() {
        if (!this.settings.allowEmptyOption && !this.getValue()) {
            this.setValue(this.$input.data('deleted-selection'))
        }
    },
    render: {
        option: function (item) {
            if (typeof item.variantBody !== 'undefined') {
                return item.variantBody;
            } else {
                return ('<div class="option">' + item.text + '</div>');
            }

        }
    }
};
$(() => {


    $('.selectize').selectize(window.selectize_options)

    const observer = new MutationObserver(function (mutations) {
        //scrollbar update will trigger observer so we want to reduce useless calls
        observer.disconnect(mutations[0].target)
        //To keep scrollbar visible when selectize refreshes options
        //there will be multiple mutations but we only need their target
        mutations[0].target.perfectScrollbar?.update()
        observer.observe(mutations[0].target, {
            childList: true,
        });
    });
    window.observer=observer;

    $('.selectize-dropdown-content').each(function () {
        this.perfectScrollbar = new PerfectScrollbar(this, {suppressScrollX: true})
        observer.observe(this, {
            childList: true,
        });
    });
})