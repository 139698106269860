const collapseButtons = document.querySelectorAll('.customCollapse');

collapseButtons.forEach((elButton) => {
    elButton.addEventListener('click', e => {
        e.preventDefault();
        const target = elButton.dataset.bsTarget;
        const targetElements = document.querySelectorAll(target);

        targetElements.forEach((elTarget) => {
            if (elTarget.classList.contains('d-none')) {
                elTarget.classList.remove('d-none');
            } else {
                elTarget.classList.add('d-none');
            }
        })
    });
});