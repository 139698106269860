export class AjaxRequest
{
    useElement($element) {
        const params = {};
        const url = $element.data('url');
        const data = $element.data();

        if (!url) {
            return $.when([]);
        }

        $.each(data, function (indexname, value) {
            if (~indexname.indexOf("param")) {
                params[indexname.substring(5).toLowerCase()] = value;
            }
        })
        return $.ajax({url: url, data: params})
    }
}