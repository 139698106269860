export class Spinner {
    createSpan() {
        return $('<span>', {
            'class': 'spinner-border spinner-border-sm',
            'role': 'status',
            'aria-hidden': 'true'
        });
    }
    getSpan(element) {
        return element.find('.spinner-border')
    }
    addSpinner(element) {
        element.find('img,i').hide();
        if (!this.getSpan(element).length) {
            element.prepend(this.createSpan());
        }
    }
    removeSpinner(element) {
        element.find('img,i').show();
        this.getSpan(element).remove();
    }
}