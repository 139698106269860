import * as bootstrap from "bootstrap";

const types = Object.freeze({
    error: 'danger',
    info: 'info',
    warning: 'warning',
    success: 'success',
});

export class Toast {
    #templateClass = '.template-toast';
    #bodyElementClass = '.toast-body__heading';
    #message = '';
    #type = 'danger'

    constructor(type, message) {
        this.#type = types[type];
        this.#message = message;
        this.show();
    }

    #getTemplate() {
        return jQuery(jQuery(this.#templateClass).html());
    }

    #getOrCreateToastContainer() {
        let $container = jQuery('.toast-container');
        if ($container.length === 0) {
            $container = $('<div>', {
                class: 'toast-container'
            });
            jQuery('body').prepend($container);
        }
        return $container;
    }

    #deleteToastContainer() {
        const $toastContainer = $('.toast-container');
        if ($toastContainer.find('.toast').length === 0) {
            $toastContainer.remove();
        }
    }

    #appendTemplate($template) {
        this.#getOrCreateToastContainer().append($template)
    }

    #addTypeClasses($template) {
        $template.find('.toast').addClass('toast-' + this.#type);
        $template.find('.icon-toast').addClass('icon-' + this.#type);
        $template.find('.progress-bar').addClass('bg-' + this.#type);

        return $template;
    }

    show() {

        const $template = this.#addTypeClasses(this.#getTemplate());

        $template.find(this.#bodyElementClass).first().html(this.#message);
        const toast = new bootstrap.Toast($template.get(0), {});
        const $progressBar = $template.find('.progress-bar');
        const durationSec = typeof $template.data('hideAfter') !== 'undefined' ? Number($template.data('hideAfter')) : 5;

        this.#appendTemplate($template);

        toast.show();

        $progressBar.animate({
            width: 0
        }, durationSec * 1000, 'linear', function () {
            toast.hide();
        })

        $template.get(0).addEventListener('hidden.bs.toast', (e) => {
            e.target.remove();
            this.#deleteToastContainer();
        })
    }
}

if (typeof toastMessages !== 'undefined') {
    $.each(toastMessages, function (i, toastMessage) {
        new Toast(toastMessage.type, toastMessage.message);
    });
}