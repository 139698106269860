(el => {
    if (!el) return;
    let timeLeft = 5;
    const intervalId = setInterval(() => {
        el.innerHTML = String( timeLeft );
        if (timeLeft <= 0) {
            clearInterval(intervalId);
            document.location.href = "/";
        }
        timeLeft--;
    }, 1000);
})(document.getElementById("error_seconds"));
