import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import { Filter } from './_filter';
class RangeSlider {

    maxPrice = 0;
    curentMaxPrice = 0;

    constructor($element) {

        this.maxPrice = Math.ceil($($element).data('max-value') / 100);
        this.curentMinPrice = Math.ceil($($element).data('current-min-value') / 100);
        this.curentMaxPrice = Math.ceil($($element).data('current-max-value') / 100);

        this.slider = $element.get(0);
        noUiSlider.create(this.slider, {
            start: [this.curentMinPrice, this.curentMaxPrice],
            connect: true,
            range: {
                'min': 0,
                'max': this.maxPrice
            },
            format: wNumb({
                decimals: 0,
                thousand: '',
                suffix: 'zł',
            })
        });

        this.registerUpdateEvent();
        this.registerDragEndEvent();
        this.registerPriceChangeEvents();
        this.registerFreeCheckboxEvent();
    }

    registerUpdateEvent() {
        const self = this;
        this.slider.noUiSlider.on('update', function (values, handle) {
            $('.price-input-' + handle).val(values[handle]).data('raw-value', self.getFormatedPrice(values[handle]));
        });
    }

    registerDragEndEvent() {
        const self = this;
        this.slider.noUiSlider.on('end', function (values, handle) {
            $(self.slider).closest('.filter').find('.filter-range__end').trigger('blur');
        });
    }

    registerPriceChangeEvents() {
        const self = this;
        $('.price-input-0').on('change', function () {
            self.slider.noUiSlider.set([this.value, null]);
            $(this).data('raw-value', self.getFormatedPrice(this.value));
        });

        $('.price-input-1').on('change', function () {
            self.slider.noUiSlider.set([null, this.value]);
            $(this).data('raw-value', self.getFormatedPrice(this.value));
        });
    }

    registerFreeCheckboxEvent() {
        const self = this;
        $('.filter-show-only-free').on('change', function () {
            if (this.checked) {
                self.slider.noUiSlider.set([0, 0]);
            } else {
                self.slider.noUiSlider.set([0, self.maxPrice]);
            }
            $(this).closest('.filter').find('.filter-range').addClass('filter-range--used');
            Filter.updateUrl();
        });
    }

    getFormatedPrice(price) {
        return price.slice(0, -2) * 100;
    }
}

$(() => {
    $('.range-slider').each(function () {
        new RangeSlider($(this));
    });
})
