$(function () {
    $('.filter__list').on('click', '.filter__btn', function (e) {
        $(this).toggleClass('is-active');
    });

    $('.filter').on('click', '.filter__btn--clear', function (e) {
        $('.filter__list .filter__btn', e.delegateTarget).removeClass('is-active');
        $('form', e.delegateTarget).trigger('reset');
    });

    $('[data-filter]').on('click', function (e) {
        let selector = $(this).data('filter');
        let filters = $('.filters');

        if (selector) {
            filters.children().addClass('d-none');
            $(selector).removeClass('d-none');
            $('.products-list__item').addClass('col-lg-4');
            $('.products-list__item--big:not(.products-list__item--first)').removeClass('d-lg-block').prev().removeClass('d-lg-none');
            $('.products-list__item--first').addClass('col-lg-8');
        }

        filters.removeClass('d-none');
    });

    $('.filters').on('click', '.filter__btn--close', (e) => {
        $(e.delegateTarget).addClass('d-none');
        $('.products-list__item').removeClass('col-lg-4');
        $('.products-list__item--big:not(.products-list__item--first)').addClass('d-lg-block').prev().addClass('d-lg-none');
        $('.products-list__item--first').removeClass('col-lg-8');
    });
});