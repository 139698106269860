import {Toast} from "../../components/toast/js/toast";
import * as bootstrap from "bootstrap";

$(function () {
    $('.orders-list').on('click', '.show-details-btn', function () {
        const $this = $(this);
        const url = $this.data('url');

        if (!url) {
            return;
        }

        const $orderLine = $this.closest('.accordion-item');
        const $detailsBlock = $orderLine.find('.accordion-collapse');
        const isLoaded = $detailsBlock.data('loaded');
        const $buttons = $this.closest('.order-header').find('.show-details-btn');

        if (!isLoaded) {
            $this.attr('disabled', true);
            $.ajax({
                url: url
            }).done(function (result) {
                if (typeof result.content == undefined) {
                    return;
                }
                $detailsBlock.append(result.content);
                $detailsBlock.data('loaded', true);
                toggleDetails($orderLine, $detailsBlock, $buttons);
            }).always(function () {
                $this.removeAttr('disabled');
            });
        } else {
            toggleDetails($orderLine, $detailsBlock, $buttons);
        }
    });

    $(document).on('click', 'a.expanded-end--download-btn', function (e) {
        e.preventDefault();

        const $a = $(this);
        const $spinnerContainer = $a.parent().find('.expanded-end--download-btn.d-none');
        const url = $a.attr('href');

        $a.addClass('d-none');
        $spinnerContainer.removeClass('d-none');

        $.ajax({
            url,
            method: 'GET',
            xhrFields: {
                responseType: 'blob'
            },
            success: function (data, status, xhr) {
                const blob = new Blob([data], {type: 'application/pdf'});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = xhr.getResponseHeader('x-download');
                link.click();
            },
            error: function () {
                new Toast('error', translations['app.customer.orders.download-invoice-error.label']);
            },
            complete: function () {
                $a.removeClass('d-none');
                $spinnerContainer.addClass('d-none');
            }
        })
    })
});

function toggleDetails(orderLine, detailsBlock, btn) {
    const btnArrowDown = btn.find('.icon-arrow-down');
    const btnArrowUp = btn.find('.icon-arrow-up');
    const btnLabel = btn.find('.show-details--label');

    new bootstrap.Collapse(detailsBlock, {
        toggle: true
    });

    detailsBlock.get(0).addEventListener('hidden.bs.collapse', function () {
        orderLine.removeClass('accordion-item--expanded')
        btnArrowDown.addClass('d-none');
        btnArrowUp.removeClass('d-none');
        btnLabel.html(translations['app.button.expand.label']);
    });

    detailsBlock.get(0).addEventListener('shown.bs.collapse', function () {
        orderLine.addClass('accordion-item--expanded')
        btnArrowDown.removeClass('d-none');
        btnArrowUp.addClass('d-none');
        btnLabel.html(translations['app.button.collapse.label']);
    });
}
