import * as bootstrap from 'bootstrap';
import { AjaxRequest } from "./ajax-request";

$(() => {
    const { useElement } = new AjaxRequest();
    $('.delete-card-btn').on('click', function () {
        const $this = $(this);
        const $modal = $('#'+$this.data('modalId'));
        useElement($this).done(function (response) {
            if (response.success) {
                $this.closest('.saved-card').remove();
                if($('.saved-card').length === 0){
                    $('.payment-cards__empty, .payment-cards').toggleClass('d-none');
                }
                if ($modal) {
                    bootstrap.Modal.getOrCreateInstance($modal).hide();
                }
            }
        })
    });
});
