const header = $('.header');
const submenu = $('.submenu')
$(function () {
    $(window).on('scroll', function () {
        if ($(this).scrollTop() >= (header.height() + submenu.height())) {
            header.addClass('compact-header');
        } else {
            header.removeClass('compact-header');
        }
    }).trigger('scroll');
})