import IMask from "imask";
import {CustomValidator} from './validator'
import {Spinner} from './spinner'

function invalidHandler() {
    CustomValidator.markInvalid(this);
}

export function initIMask($container) {
    /** DO NOT REPLICATE - use .mask + mask-pattern instead (look below) **/
    const cVatIds = $container.find('.vat_id-validate').get();
    for (let i = 0; i < cVatIds.length; i++) {
        (function (companyVatId) {
            IMask(companyVatId, {
                mask: /^\d{0,10}$/
            });
        })(cVatIds[i]);
    }

    /** DO NOT REPLICATE - use .mask + mask-pattern instead (look below) **/
    const cPostCodeIds = $container.find('.post_code-validate').get();
    for (let i = 0; i < cPostCodeIds.length; i++) {
        (function (cPostCodeId) {
            IMask(cPostCodeId, {
                mask: '00-000'
            });
        })(cPostCodeIds[i]);
    }

    /** DO NOT REPLICATE - use .mask + mask-pattern instead (look below) **/
    const cExpDateIds = $container.find('.exp_date-validate').get();
    for (let i = 0; i < cExpDateIds.length; i++) {
        (function (cExpDateId) {
            IMask(cExpDateId, {
                mask: "00/00"
            });
        })(cExpDateIds[i]);
    }


    /** DO NOT REPLICATE - use .mask + mask-pattern instead (look below) **/
    const cNumberValidatorIds = $container.find('.number-validate').get();
    for (let i = 0; i < cNumberValidatorIds.length; i++) {
        (function (cNumberId) {
            const pattern = cNumberId.getAttribute('pattern');
            let regexpPattern = /^\d*$/;
            if (pattern) {
                regexpPattern = new RegExp(pattern);
            }
            IMask(cNumberId, {
                mask: regexpPattern
            });
        })(cNumberValidatorIds[i])
    }

    const cMaskIds = $container.find('.mask').get();
    for (let i = 0; i < cMaskIds.length; i++) {
        (function (cMaskId) {
            const pattern = cMaskId.dataset?.maskPattern;
            if (pattern) {
                const regexpPattern = new RegExp(pattern);
                IMask(cMaskId, {
                    mask: regexpPattern
                });
            }
        })(cMaskIds[i])
    }
}

$(() => {
    'use strict';

    const spinner = new Spinner();
    const formsSelector = '.needs-validation';
    const fieldsSelector = ':input';
    const $doc = $(document);
    const invalidScrollOptions = {
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
    }

    $doc.on('submit', formsSelector, function (e) {
        const $form = $(this);
        const $buttons = $form.find('button');
        const $submitter = e.submitter ? $(e.submitter) : $form.find(':submit');

        spinner.addSpinner($submitter);
        $buttons.attr('disabled', 'disabled');
        $submitter.attr('disabled', 'disabled');
        //to force redrawing page on safari before page reload - TODO we should make loader (Spinner) take care of it instead
        $submitter.hide().show();

        if (CustomValidator.formValidation(this) === false) {
            e.preventDefault();
            e.stopImmediatePropagation()
            $form.trigger(jQuery.Event('form.invalid'))
            $form.trigger(jQuery.Event('interrupted.submit'), {submitter: e.submitter})
        }

        $form.addClass('was-validated');
    })

    $doc.on('interrupted.submit', formsSelector, function (e, data) {
        const $form = $(this);
        const $buttons = $form.find('button');
        const $submitter = data.submitter ? $(data.submitter) : $form.find(':submit');

        spinner.removeSpinner($submitter);
        $buttons.removeAttr('disabled');
        $submitter.removeAttr('disabled');
    })

    $doc.on('form.loaded', formsSelector, function (e, data) {
        const $form = $(this);
        $form.find(fieldsSelector).off('invalid', invalidHandler).on('invalid', invalidHandler);
    })

    $doc.on('form.invalid', formsSelector, function (e, data) {
        const firstInvalid = $(this).find('.is-invalid').first().get(0);
        if (firstInvalid) {
            firstInvalid.scrollIntoView(invalidScrollOptions);
        }
    })

    $doc.on('blur change', formsSelector + ' ' + fieldsSelector, function () {
        const $this = $(this)
        $this.removeClass('is-invalid is-valid')
        this.setCustomValidity('')
        CustomValidator.elementValidation(this)
        if (!$this.hasClass('is-invalid')) {
            $this.addClass('is-valid')
        }
    })

    const firstInvalid = $doc.find('.is-invalid').first().get(0);
    if (firstInvalid) {
        firstInvalid.scrollIntoView(invalidScrollOptions);
    }

    $(formsSelector).trigger('form.loaded');

    initIMask($doc)
});