
// ajax email validate
import {CustomValidator} from "./validator";

const cEmails = document.querySelectorAll('.email-profile');


cEmails.forEach((emailInput) => {
    const form = emailInput.closest('form');
    form.addEventListener('submit', (e) => {

        e.preventDefault();
        e.stopPropagation();

        if (emailInput.value.toLowerCase() === emailInput.dataset.savedEmail.toLowerCase()) {
            emailInput.classList.remove('is-invalid');
            document.querySelector('.DataBlock__e-mail .data-block-header__btn').click();
            return false;
        }

        const submitInput = form.querySelector('button[type="submit"]');

        submitInput.setAttribute('disabled', 'disabled');
        emailInput.setAttribute('readonly', 'readonly');

        const span = document.createElement('span');
        span.className = "spinner-border spinner-border-sm";
        span.setAttribute('role', 'status');
        span.setAttribute('aria-hidden', "true");
        submitInput.prepend(span);

        const xhr = new XMLHttpRequest();
        xhr.open("POST", form.getAttribute('action'), true);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.onload = (e) => {
            if (xhr.status !== 200) {
                const retJson = JSON.parse(xhr.responseText);
                if (retJson) {
                    emailInput.parentNode.querySelector('.invalid-feedback').innerHTML = retJson.message;
                    emailInput.classList.add('is-invalid');
                }

                emailInput.removeAttribute('readonly');
                submitInput.removeAttribute('disabled');

                span.remove();

                return false;
            }

            window.location.reload();
        }

        xhr.send(new FormData(form));
    });

});

$(() => {

    jQuery(document).on('submit', '#emailChangeModal form, #passwordChange form', function (e) {
        e.preventDefault();
        const $form = $(this);

        $.ajax({
            url: $form.attr('action'),
            data: $form.serialize(),
            method: 'POST',
            success: function () {
                window.location.reload();
            },
            error: function (response) {
                CustomValidator.markErrors(response.responseJSON, $form);
                $form.trigger('interrupted.submit', {submitter: e.submitter})
                $form.trigger(jQuery.Event('form.invalid'))
                if($.inArray('token',response)){
                    $form.find('input[name*="[_token]"]').val(response.responseJSON.token);
                }
            }
        });
    });

    jQuery(document).on('blur', '#password_change_password_old', function (e) {
        e.preventDefault();
        const $form = $(this).closest('form');

        $.ajax({
            url: '/profile/valid-pasword',
            data: $form.serialize(),
            method: 'POST',
            success: function (data) {
                if($.inArray('token',data)){
                    $form.find('input[name*="[_token]"]').val(data.token);
                }
            },
            error: function (response) {
                CustomValidator.markErrors(response.responseJSON, $form);
                $form.trigger('interrupted.submit', {submitter: e.submitter})
                $form.trigger(jQuery.Event('form.invalid'))
                if($.inArray('token',response.responseJSON)){
                    $form.find('input[name*="[_token]"]').val(response.responseJSON.token);
                }
            },
        });
    });
});
document.addEventListener('hide.bs.modal' , function (e) {
    if($(e.target).attr('id') === 'passwordChange'){
        $('#passwordChange form').removeClass('was-validated').addClass('needs-validation');
        $('#passwordChange input').val('').removeClass('is-valid is-invalid');
    }
});