import Swiper, {Navigation, Pagination, Autoplay} from 'swiper'

const paginationConf = {
    el: '.swiper-pagination',
    bulletClass: 'swiper-pagination__bullet',
    bulletActiveClass: 'swiper-pagination__bullet-active',
    dynamicBullets: true,
    renderBullet: function (index, className) {
        return '<span class="' + className + '"><span class="swiper-pagination__bullet--inside"><span class="swiper-pagination__bullet-dot"></span></span></span>';
    },
}

class SwiperCarousel {
    static init($elements, options) {
        //Each element needs it's own instance, otherwise they will share navigation
        $.each($elements, (i, element) => {
            SwiperCarousel.create(element, options);
        })
    }

    static initBanner($elements, optionsBanner) {
        //Each element needs it's own instance, otherwise they will share navigation
        $.each($elements, (i, element) => {
            SwiperCarousel.createBanner(element, optionsBanner);
        })
    }

    static create(element, options) {
        const $container = $(element);
        if (!$container.hasClass('.swiper')) {
            element = $container.find('.swiper').get();
        }

        let carouselOptions = {
            modules: [Navigation, Pagination, Autoplay],
            slidesPerView: Number(element[0].dataset?.slidesPerView ?? 4),
            slidesPerGroup: Number(element[0].dataset?.slidesPerGroup ?? 4),
            spaceBetween: 40,
            observer: true,
            observerParents: true,
            navigation: {
                prevEl: $container.find('.swiper-button-prev').get(),
                nextEl: $container.find('.swiper-button-next').get(),
            },
            pagination: {
                clickable: true,
            },
        }

        const delay = Number(element[0].dataset?.swiperAutoplayDelay);
        if (!isNaN(delay) && delay > 0) {
            carouselOptions = $.extend(carouselOptions, {
                loop: true,
                autoplay: {
                    delay: delay
                }
            })
        }

        carouselOptions = $.extend(true, carouselOptions, {
            breakpoints: {
                // when window width is >= 320px
                0: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 20,
                    pagination: paginationConf,
                },
                // when window width is >= 480px
                361: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 30,
                    pagination: paginationConf,
                },
                // when window width is >= 940px
                976: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 40,
                }
            },
        })


        if (typeof options === 'object') {
            carouselOptions = $.extend(carouselOptions, options)
        }

        return new Swiper(element, carouselOptions)
    }
    static createBanner(element, options) {
        const $container = $(element);
        if (!$container.hasClass('.swiper')) {
            element = $container.find('.swiper').get();
        }

        let carouselOptions = {
            modules: [Navigation, Pagination, Autoplay],
            slidesPerView: Number(element[0].dataset?.slidesPerView ?? 4),
            slidesPerGroup: Number(element[0].dataset?.slidesPerGroup ?? 4),
            spaceBetween: 40,
            navigation: {
                prevEl: $container.find('.swiper-button-prev').get(),
                nextEl: $container.find('.swiper-button-next').get(),
            },
            pagination: {
                clickable: true,
            },
        }

        const delay = Number(element[0].dataset?.swiperAutoplayDelay);
        if (!isNaN(delay) && delay > 0) {
            carouselOptions = $.extend(carouselOptions, {
                loop: true,
                autoplay: {
                    delay: delay
                }
            })
        }

        if (element[0].dataset?.swiperPagination === 'enabled') {
            carouselOptions = $.extend(true, carouselOptions, {
                pagination: paginationConf
            })
        }

        if (typeof options === 'object') {
            carouselOptions = $.extend(carouselOptions, options)
        }

        return new Swiper(element, carouselOptions)
    }

}

$(() => {
    //SwiperCarousel.init($('.carousel'));
    SwiperCarousel.initBanner($('.carousel--centered'));
    SwiperCarousel.init($('.carousel--products'));
})

export {
    SwiperCarousel
}
