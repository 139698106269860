import React, { lazy } from 'react'
import * as Player from '../../../node_modules/@e-kiosk-pl/e-kiosk-player'
import { createRoot } from 'react-dom/client'

function player (options) {
    const playerConfig = {
        baseUrl: 'https://player.ekiosk.pl/api/v1',
        wsUrl: 'wss://player.ekiosk.pl/wss',
        players: {
            pdf: lazy(() => import('../../../node_modules/@e-kiosk-pl/e-kiosk-player-pdf')),
        },
    }

    options = $.extend({
        token: '',
        userId: 0,
        resourceId: 0,
        type: 'pdf',
        title: '',
        description: '',
        onClose: () => window.history.back(),
    }, options)

    const resource = {
        type: options.type,
        id: parseInt(options.resourceId),
    }

    return <Player.EkioskPlayer
        userId={parseInt(options.userId)}
        resource={resource}
        token={options.token}
        config={playerConfig}
        onClose={options.onClose}
        navbar={{title: options.title, description: options.description}}
    />
}

export function initPlayer (container) {
    const root = createRoot(container)
    root.render(player(container.dataset))
}

$(document).ready(function () {
    const $containers = $('.web-player')
    $.each($containers, (i, e) => {
        initPlayer(e)
    })

    $(document).on('click', '.open-web-reader__btn', function (e) {
        e.preventDefault()
        const objectId = $(this).data('object-id')
        if (objectId) {
            window.location.href = '/czytaj/' + objectId + '/pdf'
        }
    })
})
