import * as bootstrap from "bootstrap";
import PerfectScrollbar from 'perfect-scrollbar';
import {Toast} from "../../components/toast/js/toast";

$('.product-variant-option:not(:checked), .product__formats--option[data-variant-url]:not(:checked), .product-variant-select, .btn-check-box__input').on('change', function (e) {
    if ($(this).is('select')) {
        const s = this.selectize;
        let variantUrl = s.options[s.items[0]].variantUrl;
        if(typeof variantUrl !== 'undefined' && variantUrl!== '')
        {
            localStorage.setItem('scrollTop', $(window).scrollTop());
            window.location.href = s.options[s.items[0]].variantUrl;
        }else{
            let variantRelease = s.options[s.items[0]].variantRelease;
            $('#product-release-radio-'+variantRelease).prop('checked',true);
            $('.add-cart-button, .add-cart-link, .fast-track-button').data('subscription-start',variantRelease);
        }
    } else {
        $(this).closest('.btn-check-box').addClass('active');
        localStorage.setItem('scrollTop', Math.round($(window).scrollTop()));
        window.location.href = $(this).data('variant-url');
    }
});

$(function () {
    const scrollTop = localStorage.getItem('scrollTop');
    localStorage.removeItem('scrollTop')
    if (scrollTop) {
        $(window).scrollTop(Number(scrollTop));
    }
})


$('.product-mutation-btn').on('click', function (e) {
    e.preventDefault();

    const $mutationModal = $('#mutationChoiceModal');
    if ($mutationModal.length > 0) {
        bootstrap.Modal.getOrCreateInstance($mutationModal).show();
        const container = document.querySelector('.modal--scrollable .modal-body');
        const ps = new PerfectScrollbar(container, {suppressScrollX: true});
        setTimeout(function () {
            ps.update();
        }, 500);

    }
});

$(document).on('change', '.product__formats--option', function () {
    const $input = $(this);
    const $item = $input.closest('.product__formats--item');

    if (this.checked) {
        const $itemParent = $item.closest('.product__formats--list');

        $itemParent.find('.product__formats--item').removeClass('active');
        $item.addClass('active');
    } else {
        $item.removeClass('active')
    }
})

$(document).on('click', '.product__formats--item', function () {
    $(this).find('.product__formats--option').prop("checked", true).trigger('change');
});

$(document).on('click', '.btn-check-box', function () {
    $(this).find('input').trigger('change');
})

$('.fast-buy-np-button').on('click', function () {
    const $this = $(this);
    const $modal = $('#quickPurchaseModal');

    if (!$modal.length) return;

    const $form = $modal.find('form');
    const index = $this.data('product-index');
    const id = $this.data('product-id');

    $form.attr('action', String($this.data('action-url')).replace(/\/\d+$/, '/' + id));

    ((src, $div) => {
        $div.empty();
        if (!src) return;
        jQuery('<img>', {src}).appendTo($div);
    })(
        $this.data('product-cover'),
        $modal.find('.product__cover'),
    );

    $modal.find('.product__price').html(jQuery(`#np-product-price-${index}`).html());
    $modal.find('.product__points').html($this.data('product-points'));
    $modal.find('.product__name').html($this.data('product-name'));
    $modal.find('.nexto-points-box').removeClass('to-select').addClass('disabled');
});

function setMutationData(mutationId) {
    $('.add-cart-button').data('mutation', mutationId);
}

setMutationData($('.product-mutation').val());

$('.product-mutation').on('change', function () {

    setMutationData($(this).val());
});

$(document).on('change', '#quick_purchase_invoice', function () {
    const wrapper = $('.invoice-data-wrapper');
    if ($(this).prop('checked')) {
        wrapper.removeClass('d-none');
    } else {
        wrapper.addClass('d-none');
    }
}).trigger('change');


$(document).on('show.bs.modal', '#customerAddressesModal', function () {
    $('#quickPurchaseModal').addClass('half-opacity');
    const $customerModal = $('#customerAddressesModal');
    if ($customerModal.length > 0 && $customerModal.find('.address:not(.show-form)').length === 0) {
        $customerModal.find('.addresses-block-header__btn').trigger('click');
    }
});

$(document).on('hide.bs.modal', '#customerAddressesModal', function () {
    $('#quickPurchaseModal').removeClass('half-opacity');
});

jQuery(document).on('click', '#customerAddressesModal button.hide-form', function () {
    const $customerModal = $('#customerAddressesModal');
    if ($customerModal.find('.address:not(.show-form)').length === 0) {
        bootstrap.Modal.getOrCreateInstance($customerModal).hide();
    }
});

let disabledOpenDownloadModal = false

jQuery(document).on('click', '.download-product', function (e) {
    e.preventDefault();

    if (disabledOpenDownloadModal) {
        return;
    }

    disabledOpenDownloadModal = true;
    const $button = $(this);

    const objectUuid = String($button.data('object'));
    const customerUuid = String($button.data('customer'));

    if (objectUuid.trim() === '' || customerUuid.trim() === '') {
        return;
    }

    const $wrapper = (function () {
        const el = jQuery('#download-product-wrapper');
        if (el.length) return el;
        return jQuery('<div id="download-product-wrapper">').hide().appendTo('body');
    })();

    const url = `/download-product-modal/${customerUuid}/${objectUuid}`;

    $.ajax({
        url
    }).done(function (response) {
        const $modal = $(response);

        if ($modal.length === 0) {
            disabledOpenDownloadModal = false;
            return
        }
        $wrapper.html('');
        $wrapper.append($modal);
        bootstrap.Modal.getOrCreateInstance($modal).show();
        $wrapper.show();

        $modal.find('.download-product-btn--wait').filter(':not(.d-none)').each(function () {
            prepareDataToGenerateFile($(this));
        });

        $modal.get(0).addEventListener('hidden.bs.modal', function (event) {
            disabledOpenDownloadModal = false;

            for (const [key, value] of Object.entries(intervalType)) {
                clearInterval(intervalType[key]);
                delete intervalType[key];
            }

        });

        const tooltips = $('.modal .btn-tooltip');
        const modalTooltipList = [...tooltips].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));


    }).fail(function () {
        disabledOpenDownloadModal = false;
        new Toast('error', translations['error-message']);
    });
})

let intervalType = {};
$(document).on('click', '.download-product-btn--generate', function (e) {
    e.preventDefault()
    prepareDataToGenerateFile($(this));
});

function prepareDataToGenerateFile($button) {
    const object = $button.data('object');
    const type = $button.data('type');
    const customer = $button.data('customer');

    if (!object || !type || !customer) {
        return;
    }

    const $container = $button.closest('.download-product__links-list--item-actions');
    const intervalTime = $container.data('intervalTime') ?? 10000;
    $container.find('.download-product-btn--generate').addClass('d-none');
    $container.find('.download-product-btn--wait').removeClass('d-none');

    intervalType[type] = setInterval(function () {
        generateFile(customer, object, type, $container);
    }, intervalTime);
    generateFile(customer, object, type, $container);

    setTimeout(() => {
        if (typeof intervalType[type] !== 'undefined') {
            $container.closest('.modal').find('.download-product__info--too-long')?.removeClass('d-none');
        }
    }, 30000);
}

function generateFile(customer, object, type, $container) {
    const url = `/download/generate-file/${object}/${customer}/${type}`;

    $.ajax({
        url
    }).done(function (response) {
        $container.find('.download-product-btn').each(function () {
            $(this).addClass('d-none');
        })

        if (response.status === 'wait') {
            $container.find('.download-product-btn--wait').removeClass('d-none');
        } else if (response.status === 'finish') {
            clearInterval(intervalType[type]);
            delete intervalType[type];
            const $downloadButton = $container.find('.download-product-btn--download');
            $downloadButton.removeClass('d-none');
            new Toast('success', response.message);
            $downloadButton.data('hash', response.hash);
        } else {
            $container.find('.download-product-btn--generate').removeClass('d-none');
            clearInterval(intervalType[type]);
            delete intervalType[type];
            new Toast('error', typeof response.message !== 'undefined' ? response.message : translations['error-message']);
        }

    }).fail(function (jqXHR, textStatus, errorThrown) {
        $container.find('.download-product-btn').each(function () {
            $(this).addClass('d-none');
        })
        $container.find('.download-product-btn--generate').removeClass('d-none');
        clearInterval(intervalType[type]);
        delete intervalType[type];
        new Toast('error', translations['error-message'])
    })
}

$(document).on('click', '.download-product-btn--download', function (e) {
    const $button = $(this);
    const hash = $button.data('hash');
    const url = `/download/download-watermark-file/${hash}`;

    var windowReference = window.open();

    $.ajax({
        url
    }).done(function (response) {
        if (typeof response.status !== 'undefined' && response.status === 'error') {
            new Toast('error', response.message)
            return;
        }
        if (typeof response.status !== 'undefined' && response.status === 'success') {
            windowReference.location = response.downloadUrl;
        }

    }).fail(function () {
        new Toast('error', translations['error-message'])
    })
    $button.blur();
});

const detailsContentDescription = $('.details-content__description');

if (detailsContentDescription) {

    const minLinesToTruncate = 5;
    $.each(detailsContentDescription, function (i, elem) {
        if (countLines($(elem)) < minLinesToTruncate) {
            $(elem).parent().find('.load-more-description').remove();
        }
    });

    $(document).on('click', '.load-more-description', function () {
        const $this = $(this);
        $this.parent().find('.details-content__description').removeClass('text-truncate-line');
        $(this).remove();
    });

    function countLines(element) {
        let divHeight = element.height();
        let lineHeight = parseInt(element.css('line-height'));
        return Math.round(divHeight / lineHeight);
    }
}


$('.download-btn').on('click', function (e) {
    e.preventDefault();
    let $this = $(this);
    $this.prop('disabled', true);
    window.open($this.data('file'), '_blank');
    $this.prop('disabled', false);
});

$(document).on('click', '.scroll-btn', function () {
    let $this = $(this);
    $('html, body').animate({
        scrollTop: ($($this.data('target')).offset().top-50)
    }, 100);

});