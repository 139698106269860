import { CustomValidator } from '../../../scripts/components/validator'
import { Spinner } from '../../../scripts/components/spinner'
import { Toast } from '../../toast/js/toast'

$(function () {
    let $form = $('.newsletter-sign-up__form')
    let $email = $('.newsletter-sign-up__e-mail')
    let spinner = new Spinner();

    $email.on('input blur', (e) => {
        e.currentTarget.setCustomValidity('')
    })

    $email.on('input', function () {
        $(this).trigger('change')
    })

    $form.on('submit', (e) => {
        e.preventDefault()
        if (CustomValidator.formValidation(e.currentTarget) === false) {
            return false
        }

        const $form = $(e.currentTarget)
        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize(),
            success ({success, message}) {
                new Toast(success ? 'success' : 'info', message)
            },
            error ({responseJSON, message = translations['app.newsletter.sign-up.error']}) {
                if (typeof responseJSON !== 'undefined') {
                    CustomValidator.markErrors(responseJSON, $form)
                    $form.trigger('interrupted.submit', {submitter: e.submitter})
                    $form.trigger(jQuery.Event('form.invalid'))
                }
                new Toast('error', message)
            },
        }).always((e) => {
            const $buttons = $form.find('button');
            const $submitter = e.submitter ? $(e.submitter) : $form.find(':submit');

            spinner.removeSpinner($submitter);
            $buttons.removeAttr('disabled');
            $submitter.removeAttr('disabled');
        })
    })
})