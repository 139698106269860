import {Spinner} from "./spinner";

export class DeleteAddress {
    spinner
    $container
    #buttonsDeleteSelector = '.delete-address'
    #buttonsModalSelector = 'button'
    #containerSelector = '.address-block'
    #modalSelector = '.modal'

    constructor () {
        this.spinner = new Spinner();
        this.getDeleteButtons().on('click', (e) => {
            e.preventDefault()
            const $this = $(e.target)

            this.setContainer($this.closest(this.#containerSelector))
            this.markAsRun()

            if (!$this.data('id')) {
                this.reset()
                return
            }

            this.send($this)
        })
    }

    send ($element) {
        const $modal = this.getModal()
        let form = $modal.find('.modal-footer form')
        if (!form.length) {
            const csrfToken = $element.data('csrfToken')
            const addressId = $element.data('id')

            form = $('<form method="POST" class="d-none"></form>')
            const hiddenToken = $('<input type="hidden" name="delete_address[csrfToken]" />')
            hiddenToken.val(csrfToken)
            form.append(hiddenToken)

            const hiddenAddressId = $('<input type="hidden" name="delete_address[addressId]" />')
            hiddenAddressId.val(addressId)
            form.append(hiddenAddressId)

            $modal.find('.modal-footer').append(form)
        }
        form.submit()
    }

    setContainer ($container) {
        return this.$container = $container
    }

    getContainer () {
        return this.$container
    }

    getDeleteButtons () {
        return $(this.#buttonsDeleteSelector)
    }

    getDeleteButton () {
        return $(this.#buttonsDeleteSelector, this.getContainer())
    }

    getModal () {
        return $(this.#modalSelector, this.getContainer())
    }

    getModalButtons () {
        return $(this.#buttonsModalSelector, this.getModal())
    }

    markAsRun () {
        this.getModalButtons().attr('disabled', true)
        this.spinner.addSpinner(this.getDeleteButton());
    }

    reset () {
        this.spinner.removeSpinner(this.getDeleteButton());
        this.getModalButtons().removeAttr('disabled')
    }
}

export class EditAddress {
    #addressFormSelector = '.address-form'
    #addressTabsSelector = '.address-form--tabs'
    #addressesListSelector = '.data-block__content'
    #btnAddSelector = '.address--add'
    #btnShowFormSelector = '.show-form'
    #btnHideFormSelector = '.hide-form'
    #btnTabFormSelector = '.nav-link'
    #containerSelector = '.addresses-block'
    #addressBlockSelector = '.address-block'
    #titleAddSelector = '.data-block__title--add'
    #titleListSelector = '.data-block__title--list'

    $container = false

    constructor ($container) {
        if ($container) {
            this.$container = $container;
        }

        this.getHideFormButtons().on('click', (e) => {
            const $this = $(e.target)

            if ($this.data('id')) {
                this.hideForm($this.data('id'))
            } else {
                this.hideForms()
            }

            this.showAddresses()
            this.showAddButton()
            this.showListTitle()
        })

        this.getShowTabFormButtons().on('click', (e) => {
            e.preventDefault()

            const $this = $(e.target)
            $this.closest('.nav-tabs').find('.nav-item').removeClass('active')
            $this.closest('.nav-item').addClass('active')
            this.hideTabForms()
        })

        this.getShowFormButtons().on('click', (e) => {
            e.preventDefault()

            let $this = $(e.target);

            if(!$this.hasClass(this.#btnShowFormSelector)){
                $this = $(e.target).closest(this.#btnShowFormSelector);
            }

            const idAddress = $this.data('id') ?? 0
            if ($this.data('hide-other-forms')) {
                this.hideForms()
            }

            if (idAddress === 'tabs') {
                this.showAddTitle()
                this.hideAddresses()
                if ($this.data('hide-button')) {
                    this.hideAddButton()
                }
                this.getShowTabFormButtons().first().trigger('click')
            }
            this.showForm(idAddress)
        })
    }

    getContainer () {
        if (!this.$container) {
            this.$container = $(this.#containerSelector)
        }
        return this.$container
    }

    getAddressesContainer () {
        return $(this.#addressesListSelector, this.getContainer())
    }

    getTabsContainer () {
        return $(this.#addressTabsSelector, this.getContainer())
    }

    getShowFormButtons () {
        return $(this.#btnShowFormSelector, this.getContainer())
    }

    getShowTabFormButtons () {
        return $(this.#btnTabFormSelector, this.getTabsContainer())
    }

    getHideFormButtons () {
        return $(this.#btnHideFormSelector, this.getContainer())
    }

    showForm (id) {
        const $this = $(this.#addressFormSelector + '--' + id, this.getContainer())
        const container = $this.closest(this.#addressBlockSelector)
        $this.removeClass('d-none')
        container.addClass('data-block-radius-group')
        this.showHideFormButton(container)
    }

    hideForm (id) {
        const $this = $(this.#addressFormSelector + '--' + id, this.getContainer())
        const container = $this.closest(this.#addressBlockSelector)
        $this.addClass('d-none')
        container.removeClass('data-block-radius-group')
        this.hideHideFormButton(container)
    }

    hideForms () {
        const $this = $(this.#addressFormSelector, this.getContainer())
        $this.addClass('d-none')
        $this.closest(this.#addressBlockSelector).removeClass('data-block-radius-group')
        this.hideHideFormButton(this.getAddressesContainer())
        $('.address-form.collapse', this.getContainer()).removeClass('d-none');
    }

    hideTabForms () {
        $(this.#addressFormSelector, this.getTabsContainer()).addClass('d-none')
    }

    showAddresses () {
        this.getAddressesContainer().removeClass('d-none')
    }

    hideAddresses () {
        this.getAddressesContainer().addClass('d-none')
    }

    showAddButton () {
        $(this.#btnAddSelector, this.getContainer()).removeClass('d-none')
    }

    hideAddButton () {
        $(this.#btnAddSelector, this.getContainer()).addClass('d-none')
    }

    showListTitle () {
        $(this.#titleAddSelector, this.getContainer()).addClass('d-none')
        $(this.#titleListSelector, this.getContainer()).removeClass('d-none')
    }

    showAddTitle () {
        $(this.#titleListSelector, this.getContainer()).addClass('d-none')
        $(this.#titleAddSelector, this.getContainer()).removeClass('d-none')
    }

    showHideFormButton (container) {
        container.find(this.#btnShowFormSelector).addClass('d-none')
        container.find(this.#btnHideFormSelector).removeClass('d-none')
    }

    hideHideFormButton (container) {
        container.find(this.#btnShowFormSelector).removeClass('d-none')
        container.find(this.#btnHideFormSelector).addClass('d-none')
    }
}

$(() => {
    new DeleteAddress()
    new EditAddress()
})
