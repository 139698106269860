import * as bootstrap from "bootstrap";
import {CustomValidator} from "./validator";

$(function () {
    const modalSelector = '.alert'
    const confirmModalSelector = '.alert__button--confirm'
    const rejectModalSelector = '.alert__button--reject'

    const marketingActivateSelector = '.marketing-modal--activate'
    const marketingSubmitSelector = '.marketing-modal--submit'
    const modalSubmitFormSelector = 'form[data-modal-submit]'

    const $modal = $(modalSelector)
    $modal.on('click', '[data-dismiss="modal"]', (e) => {
        $(e.target).closest(modalSelector).hide()
    })

    $(marketingActivateSelector).on('click', confirmModalSelector, (e) => {
        const token = $('#activation_token').val()
        if (token) {
            $.ajax({
                url: '/customer/accept-marketing',
                data: {token},
            })
        }

        $(e.target).closest(modalSelector).hide()
    })

    const $modalSubmit = $(marketingSubmitSelector)
    const marketingFormOnSubmitHandler = (e) => {
        const $form = $(e.target)

        if (CustomValidator.formValidation($form.get(0)) === false) {
            e.preventDefault();
            return;
        }

        const $modalContainers = $($form.data('modal-submit'));

        $.each($modalContainers, (i, modalContainer) => {
            const $modalContainer = $(modalContainer);
            const $consentField = $form.find($modalContainer.data('consent-field'))
            if (!$consentField.prop('checked')) {
                e.preventDefault()
                $form.trigger('interrupted.submit', {submitter: e.submitter})
                $modalContainer.find(modalSelector).css('display', 'flex');
            }
        })
    }

    $(modalSubmitFormSelector).on('submit', marketingFormOnSubmitHandler)

    $modalSubmit.on('click', confirmModalSelector, (e) => {
        const $consentField = $($(e.delegateTarget).data('consent-field'))
        $consentField.prop('checked', true)

        $(e.target).closest(modalSelector).hide()
        $consentField.closest(modalSubmitFormSelector).find(':submit').removeAttr('disabled').click().attr('disabled', 'disabled');
    }).on('click', rejectModalSelector, (e) => {
        const $consentField = $($(e.delegateTarget).data('consent-field'))
        $consentField.prop('checked', false)
        $consentField.closest(modalSubmitFormSelector).off('submit', marketingFormOnSubmitHandler).find(':submit').removeAttr('disabled').click().attr('disabled', 'disabled');
    })

    document.addEventListener('hide.bs.modal' , function (e) {
        const parentModal = $(e.target).data('parent-modal');
        if (parentModal) {
            const $parentModal = $(parentModal);
            if ($parentModal.length > 0) {
                bootstrap.Modal.getOrCreateInstance($parentModal.get(0))?.show();
            }
        }
    }, true)
})
