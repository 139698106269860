import PerfectScrollbar from 'perfect-scrollbar'
import { createPopper } from '@popperjs/core';
import { Search } from '../../../../scripts/components/search.js';
import { reloadFirstPage } from '../../../../scripts/components/pagination';

export class Filter {

    #buttonSelector = '.filter-btn'
    #dropdownSelector = '.filter'
    #filterSelector = '.filter-selector'
    #filterSortSelector = '.filter-sort-selector'
    #filterDateSelector = '.form-input-date'
    #filterDeleteBtn = '.filter-delete'
    #filterRangeInputs = '.filter-range__start, .filter-range__end'
    #resetFiltersBtn = '.reset-filters'

    constructor($element) {
        if($element.closest('.accordion-item').length > 0){
            this.button = $element.closest('.accordion-item').find(this.#buttonSelector);
        }else{
            this.button = $element.find(this.#buttonSelector);
        }
        this.dropdown = $element.find(this.#dropdownSelector);

        this.popperInstance = createPopper(this.button.get(0), this.dropdown.get(0), {
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 15],
                    },
                },
            ],
        });

        this.registerDropdownEvent();
        this.registerFilterChangeEvent();
        this.registerDateFilterChangeEvent();
        this.registerFilterDeleteEvent();
        this.registerRangeFilterChangeEvent();
        this.registerResetFiltersEvent();
        this.registerFilterSortChangeEvent();
        this.registerPriceInputFocusEvent();
    }

    show() {
        this.dropdown.attr('data-show', '');
        this.popperInstance.update();
    }

    hide() {
        this.dropdown.removeAttr('data-show');
    }

    registerDropdownEvent() {
        const self = this
        this.button.on('click', function (e) {
            self.show();
            if (typeof self.dropdown.data('scroll-loaded') !== undefined) {
                let filterContent = self.dropdown.find('.filter-list__content').get(0);
                if (typeof filterContent !== 'undefined'){
                    new PerfectScrollbar(filterContent, { suppressScrollX: true });
                    self.dropdown.data('scroll-loaded', true);
                }
            }
        });
    }

    registerFilterChangeEvent() {
        $('.filters').off('change', this.#filterSelector).on('change', this.#filterSelector, function (e) {
            $('.filter-delete[data-value="' + $(this).val() + '"]').remove();
            Filter.updateUrl();
        });
    }

    registerFilterSortChangeEvent() {
        $('.filters').off('change', this.#filterSortSelector).on('change', this.#filterSortSelector, function (e) {
            $(this).closest('.filters__dropdown').find('.currentOrder').html($(this).next().html());
            Filter.updateUrl();
        });
    }

    registerDateFilterChangeEvent() {
        $('.filters').off('change', this.#filterDateSelector).on('change', this.#filterDateSelector, function (e) {
            let time = this.valueAsNumber;
            time = isNaN(time) ? '' : time / 1000;
            $(this).data('raw-value', time);
        });
    }

    registerRangeFilterChangeEvent() {
        $('.filters').off('blur', this.#filterRangeInputs).on('blur', this.#filterRangeInputs, function (e) {
            if ($(this).find('input').val() != '') {
                $(this).closest('.filter-range').addClass('filter-range--used');
                Filter.updateUrl();
            }
        });
    }

    registerFilterDeleteEvent() {
        $('.filters').off('click', this.#filterDeleteBtn).on('click', this.#filterDeleteBtn, function (e) {
            const $this = $(this);
            const $filter = $this.closest('.filters');
            const currentValue = $this.data('value');

            switch ($this.data('type')) {
                case 'multiselect':
                case 'category_multiselect':
                    $filter.find(".filter-selector[value='" + currentValue + "']").prop('checked', false);
                    break;
                case 'range':
                    $filter.find(".filter-range-" + currentValue).removeClass('filter-range--used');
                    break;
                case 'boolean':
                    $filter.find(".filter-boolean-" + currentValue).prop('checked', false);
                    break;
            }

            $this.remove();
            Filter.updateUrl();

        });
    }

    registerResetFiltersEvent() {
        $('.filters').off('click', this.#resetFiltersBtn).on('click', this.#resetFiltersBtn, function (e) {
            const filter = $(this).closest('.filters');
            filter.find(".filter-selector").prop('checked', false);
            filter.find(".filter-range--used").removeClass('filter-range--used');
            $('.filter-delete').remove();
            Filter.updateUrl();
        });
    }

    registerPriceInputFocusEvent() {
        $('.filters').on('focus', ".filter-range--start, .filter-range--end", function (e) {
            $(this).select();
        });
    }

    static updateUrl() {
        var filterParams = [];

        let filterName = $('.filters').data('filter-name');
        let filterType = $('.filters').data('filter-type');

        // existing filters
        $('.filter-delete').each(function () {
            const $this = $(this);
            filterParams.push([$this.data('field-name') + ($this.data('type') == 'multiselect' ? '[]' : ''), $this.data('value')]);
        });

        // checkboxes
        $('.filter-selector:checked, .filter-sort-selector:checked').each(function () {
            const $this = $(this);
            filterParams.push([$this.attr('name'), $this.val()]);

            if ($this.data('order')) {
                filterParams.push(['order', $this.data('order')]);
            }
        });

        // range
        $('.filter-range--used').each(function () {
            let start = $(this).find('.filter-range--start');
            let end = $(this).find('.filter-range--end');
            let startValue = start.data('raw-value');
            let endValue = end.data('raw-value');

            if (typeof startValue === 'undefined' || startValue === '') {
                let startValueMin = start.data('raw-value-min');
                startValue = typeof startValueMin !== 'undefined' ? startValueMin : '';
            }

            if (typeof endValue === 'undefined' || endValue === '') {
                let endValueMax = end.data('raw-value-max');
                endValue = typeof endValueMax !== 'undefined' ? endValueMax : '';
            }

            filterParams.push([
                start.attr('name'),
                startValue + ',' +
                endValue
            ])
        });

        const params = new URLSearchParams(filterParams);
        window.history.replaceState({}, '', `${location.pathname}?${params}`);

        $('.filter-search-input').each(function () {
            if ($(this).data('search-query') !== undefined) {
                params.set('listSearch[' + $(this).prop("name") + ']', $(this).data('search-query'));
            }
        });

        Filter.updateFilters(filterType, $('#search-input').val(), params);
        if (filterName === 'search') {
            const search = new Search();
            search.sendRequest($('#search-input').val());
        } else {
            reloadFirstPage();
        }
    }

    static updateFilters(filterType, searchQuery, urlParams)
    {
        if (typeof urlParams === 'undefined') {
            urlParams = new URLSearchParams(location.search);
        }

        if (searchQuery) urlParams.set('search_query', searchQuery);

        urlParams.set('filterName', filterType);
        //Quick fix to pass some context
        let searchParams = $('.load-more-btn').data('searchParams');
        if (typeof searchParams === 'object') {
            urlParams.set('search_params', JSON.stringify(searchParams));
        }

        $.ajax({
            url: '/filters',
            method: 'GET',
            data: urlParams.toString(),
            success: function (response) {

                $.each(response, function (fieldName, data) {
                    let name = '.' + fieldName + '-list';
                    let rangeFieldClass = '.' + filterType + '-' + data.type + '-' + fieldName;
                    switch (data.type) {
                        case 'multiselect':
                        case 'category_multiselect':
                            $(name).html('');
                            $.each(data.items, function (index, element) {
                                let id = fieldName + '_' + index;
                                let listItem = $('<li class="filter-list__item" data-value="' + element.name + '">');
                                let formCheck = $('<div class="form-check">');
                                let checkbox = $('<input type="checkbox" class="form-check-input filter-selector" id="' + id + '" name="' + fieldName + '[]" value="' + index + '" />');
                                let label = $('<label class="form-check-label" for="' + id + '">' + element.translated + '</label>');

                                if (element.selected) checkbox.prop("checked", true);

                                formCheck.append(checkbox);
                                formCheck.append(label);
                                $(name).append(listItem.append(formCheck));
                            });

                            const searchQuery = $(name).closest('.filter').find('.filter-search-input').data('search-query');
                            $(name).closest('.filter-list').find('.filter-list__info').toggleClass('d-none', !!searchQuery);
                            $(name).parent().parent().find('.filter-search-loader').addClass('d-none');

                            if(typeof data.items === 'undefined' && (['authors','publisher'].includes(fieldName)) )
                            {
                                let noResultBox = $(name).parent().parent().find('.filter-no-results');
                                noResultBox.find('span').text(searchQuery);
                                $(name).closest('.filter-list').addClass('d-none');
                                if (typeof searchQuery !== 'undefined' && searchQuery > '') {
                                    noResultBox.removeClass('d-none');
                                }
                            }

                            break;
                        case 'price':

                            let rangeStart = $(rangeFieldClass + '-0');
                            let rangeEnd = $(rangeFieldClass + '-1');

                            let rangeStartSelStart = rangeStart[0].selectionStart;
                            let rangeStartSelEnd = rangeStart[0].selectionEnd;
                            let rangeEndSelStart = rangeEnd[0].selectionStart;
                            let rangeEndSelEnd = rangeEnd[0].selectionEnd;

                            rangeStart.val(data.minValue).data('raw-value', data.minValue * 100).trigger('change');
                            rangeEnd.val(data.maxValue).data('raw-value', data.maxValue * 100).trigger('change');

                            rangeStart[0].selectionStart = rangeStartSelStart;
                            rangeStart[0].selectionEnd = rangeStartSelEnd;
                            rangeEnd[0].selectionStart = rangeEndSelStart;
                            rangeEnd[0].selectionEnd = rangeEndSelEnd;

                            $('.filter-range-' + fieldName).parent().find('.filter-show-only-free').prop('checked', data.minValue === 0 && data.maxValue === 0);
                            break;
                        case 'date':
                            if ($('.filter-range-' + fieldName).hasClass('filter-range--used')) {
                                $(rangeFieldClass + '-0').val(data.minValueFormatted).data('raw-value', data.minValue).trigger('change');
                                $(rangeFieldClass + '-1').val(data.maxValueFormatted).data('raw-value', data.maxValue).trigger('change');
                            } else {
                                $(rangeFieldClass + '-0').val('').data('raw-value', '').trigger('change');
                                $(rangeFieldClass + '-1').val('').data('raw-value', '').trigger('change');
                            }

                            break;
                    }
                });

                // Fill current filters block
                $('.' + filterType + '-current-filters').html('');
                $.each(response.currentFilters, function (index, element) {
                    let button = $('<button type="button" class="btn btn-secondary icon-container filter-delete" data-value="' + index + '" data-type="' + element.type + '" data-field-name="' + element.name + '">');
                    let icon = $('<i class="icon icon-sm-1 icon-times"></i>');
                    $('.' + filterType + '-current-filters').append(button.html(element.value).append(icon));
                });

                if (!$.isEmptyObject(response.currentFilters)) {
                    let resetBtn = $('<button type="button" class="btn btn-outline-secondary icon-container reset-filters">');
                    let icon = $('<i class="icon icon-sm-1 icon-times"></i>');
                    $('.' + filterType + '-current-filters').append(resetBtn.html(translations['app.filters.button.clear-all.label']).append(icon));
                }
            }
        })
    }

}

$(() => {
    let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const mobileWindowWidth = 976

    if(windowWidth < mobileWindowWidth){
        $('.filters__dropdown.d-lg-flex').remove()
    }else{
        $('.filters-accordion').remove();
    }

    window.onresize = function(){
        let newWindowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if(newWindowWidth < mobileWindowWidth && windowWidth >=mobileWindowWidth || newWindowWidth >=mobileWindowWidth && windowWidth < mobileWindowWidth )
        {
            window.location.reload();
        }

        windowWidth = newWindowWidth;
    };

    $('.filters__dropdown').each(function () {
        new Filter($(this));
    });

    $(document).on('click', function (e) {
        const target = $(e.target);
        $('.filters__dropdown').each(function () {
            if (!$(this).has(target).length) {
                $(this).find('.filter').removeAttr('data-show');
            }
        });
    });
})