import PerfectScrollbar from 'perfect-scrollbar'
import { debounce } from 'radash'
import { Filter } from './_filter';

$(() => {
    const debounce_fun = debounce({ delay: $('.filters').data('search-debounce-time') }, () => {
        Filter.updateUrl();
    });

    $('.filter-search-input').on('keyup', function (e) {
        const $this = $(this);
        if ($this.val().length == 0 || $this.val().length > 3) {
            $this.parent().parent().find('.filter-search-loader').removeClass('d-none');
            $this.parent().parent().parent().find('.filter-no-results').addClass('d-none');
            $this.parent().parent().parent().find('.filter-list').removeClass('d-none');
            $this.data('search-query', $this.val());
            debounce_fun();
        }
    });
})
