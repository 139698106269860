$(function () {
    const submenu = $('.submenu')
    const nextElement = submenu.next();
    
    if (nextElement.hasClass('bg-light') || nextElement.children(':first-child').hasClass('bg-light')) {
        submenu.addClass('bg-light')
        $('.top-nav-line').css('display','block');
    }
});

$(window).on('resize', function () {
    $('.navigation-bar-mobile .top-menu').css('height', $('.navigation-bar-mobile .fixed-top').outerHeight() + 'px');
}).trigger('resize');

$(document).on('click', '.navigation-bar-mobile #menu-hamburger', function () {
    $(this).find('img').toggleClass('d-block d-none');
});

$(document).on('click', '.navigation-bar-mobile .accordion-button.all .accordion-button', function () {
    const $this = $(this);
    if ($this.hasClass('collapsed')) {
        $this.parent().removeClass('active');
    } else {
        $this.parent().addClass('active');
    }
});
