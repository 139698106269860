export {
    loaderBtn as loaderBtn,
    updateProgress as updateProgress,
    reloadFirstPage as reloadFirstPage
}
import {Spinner} from "./spinner";
import {UserScreen} from './user-screen';
import {hideBuyButtons} from "./owned";

$(function () {
    let repeat = 0;
    let userScreen = new UserScreen();
    let limitMobile = userScreen.getLimitMobile();

    let buttons = document.querySelectorAll(".load-more-btn");
    buttons.forEach((btn) => {
        const $btn = $(btn);
        const page = parseInt(btn.dataset.page);
        const paginationCount = parseInt(btn.dataset.paginationCount);
        repeat = parseInt(btn.dataset.repeat);

        // change for more lists
        if (page === 2 && !userScreen.isMobileView() && paginationCount > limitMobile && repeat > 0) {
            repeatLoadMore(getParamsFromBtn($btn));
        }

        btn.addEventListener("click", (event) => {
            repeatLoadMore(getParamsFromBtn($btn))
        });
    });
});

function reloadFirstPage() {
    //all buttons on the page...
    const $btn = $('.load-more-btn');

    //Pagination should be an object that stores this data and communicates with backend
    $btn.data('page', 1);
    $btn.removeData('loadMore').removeAttr('loadMore');
    $($btn.data('containerSelector')).html('');

    repeatLoadMore(getParamsFromBtn($btn))?.done(() => {
        //I could modify PaginationTrait to change how it works with repeat = 0 && page = 1, but I don't have enough time and it may break something else
        let userScreen = new UserScreen();
        if (!userScreen.isMobileView()) {
            $btn.data('page', 2);
            repeatLoadMore(getParamsFromBtn($btn));
        }
    })
}

function getParamsFromBtn($btn) {
    return {
        btn: $btn,
        url: $btn.data('paginationUrl'),
        page: parseInt($btn.data('page')),
        container: $($btn.data('containerSelector')),
        productsCount: $btn.data('count'),
        paginationContainer: $($btn.closest('.pagination-info-box')),
        searchParams: $btn.data('searchParams'),
        loadMore: $btn.data('loadMore'),
    }
}

function repeatLoadMore(params) {

    let userScreen = new UserScreen();
    let limitMobile = userScreen.getLimitMobile();
    let addedPage = userScreen.getAddedPage();
    let computedLimit = userScreen.getComputedLimit();

    if (!params.url || !params.page || !params.container) {
        return;
    }

    if (params.loadMore !== undefined && params.loadMore === 'false') {
        productPaginationMagicHide(params.container);
        params.btn.addClass('d-none');
        return;
    }

    loaderBtn(params.btn, true, params.container);

    let repeat = 0;
    if (params.page === 2 && !userScreen.isMobileView()) {
        repeat = 1;
        computedLimit = limitMobile;
        addedPage = 1;
    }

    const urlParams = new URLSearchParams(location.search);
    const char = params.url.includes('?') ? '&' : '?';

    return $.ajax({
        url: params.url + char + urlParams,
        data: {
            'page': params.page,
            'computedLimit': computedLimit,
            'search_params': params.searchParams,
            'repeat': repeat,
        }
    }).done(function (result) {
        if (typeof result.content == 'undefined' || typeof result.more == 'undefined') {
            return;
        }

        productPaginationMagicHide(params.container);
        params.container.append(result.content);

        params.btn.data('loadMore', result.more.toString());
        params.btn.data('page', params.page + addedPage);
        params.btn.toggleClass('d-none', !result.more);

        const total = typeof result.total === 'undefined' ? null : result.total;

        updateProgress(params.paginationContainer, result.count, total, params.page === 1);
        hideBuyButtons();

    }).always(function () {
        loaderBtn(params.btn, false, params.container);
    });
}

function productPaginationMagicHide($container) {
    $container.find(".product-dynamic-hide")?.removeClass("d-none").addClass('d-lg-none').removeClass('product-dynamic-hide');
    $container.find(".product-last-hide")?.removeClass("d-none").removeClass('d-lg-block').removeClass('product-last-hide');
}

function loaderBtn(btn, loading, container) {
    const prototype = $(btn).data('prototype');
    const limit = $(btn).data('pagination-limit');
    const spinner = new Spinner();

    if (loading) {
        spinner.addSpinner(btn);
        if (prototype != null) {
            addSkeletonLoadingBoxes(container, prototype, limit);
        }
        btn.attr('disabled', 'disabled');
        btn.find('.btn-text').addClass('d-none');
    } else {
        spinner.removeSpinner($(btn));
        removeSkeletonLoadingBoxes(container)
        btn.removeAttr('disabled');
        btn.find('.btn-text').removeClass('d-none');
    }

}

function updateProgress($paginationContainer, items, total = null, reset = false) {
    const totalCounter = $paginationContainer.find('.total-products-counter');

    const counter = $paginationContainer.find('.products-counter');
    if (total === null) {
        total = parseInt(totalCounter.text())
    }

    items = parseInt(counter.text()) + items;

    if (total < items) {
        items = total;
    }

    counter.text(items);
    totalCounter.text(total);
    let proc = total === 0 ? 100 : (items / total) * 100;
    $paginationContainer.find('.load-more .progress-bar').css({'width': proc + '%'}).attr('aria-valuenow', proc);

    $('.list-header__title--count').text(`(${total})`);
}

function addSkeletonLoadingBoxes(container, prototype, count) {
    let html = '';
    for (let i = 0; i < count; i++) {
        html += prototype;
    }
    container.append(html);
}

function removeSkeletonLoadingBoxes(container) {
    container.find('.skeleton-box-container').remove();
}