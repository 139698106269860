import * as bootstrap from 'bootstrap';


jQuery(function () {

    if (sessionStorage.getItem('allow_google_pay') === null) {
        jQuery.getScript('https://pay.google.com/gp/p/js/pay.js', function () {
            new google.payments.api.PaymentsClient({environment: 'PRODUCTION'})
                .isReadyToPay({
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: [{
                        type: 'CARD',
                        parameters: {
                            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                            allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA", "ELECTRON", "MAESTRO", "ELO", "ELO_DEBIT"],
                        },
                    }],
                })
                .then(function () {
                    return true;
                })
                .catch(function () {
                    return false;
                })
                .then(function (result) {
                    sessionStorage.setItem('allow_google_pay', result ? '1' : '0');
                });
        });
    }

    if (sessionStorage.getItem('allow_apple_pay') === null) {
        if (window.ApplePaySession) {
            if (ApplePaySession.canMakePayments()) {
                sessionStorage.setItem('allow_apple_pay', '1');
            } else {
                sessionStorage.setItem('allow_apple_pay', '0');
            }
        } else {
            sessionStorage.setItem('allow_apple_pay', '0');
        }
    }

    function updateCardVisibility($parent) {
        $parent.find('.card-payment[data-value="ap"]').toggleClass('d-none', sessionStorage.getItem('allow_google_pay') !== '1');
        $parent.find('.card-payment[data-value="jp"]').toggleClass('d-none', sessionStorage.getItem('allow_apple_pay') !== '1');
    }

    updateCardVisibility(jQuery(document));

    jQuery(document).on('quickPurchase.opened', '#quickPurchaseModal', function () {
        updateCardVisibility(jQuery(this));
    });
});

let previousPayMethod = null;

jQuery(document).on('click', '.card-payment.to-select', function (e) {
    e.preventDefault();

    setActivePayment($(this));
});

setDefaultPayment();

jQuery(document).on('quickPurchase.opened', '#quickPurchaseModal', setDefaultPayment);

jQuery('#payMethodsModal').on('click', '.to-select', function (e) {
    e.preventDefault();

    const $this = $(this);
    const $copyContent = $('#selected-bank-transfer-template')[0].content.cloneNode(true);
    const modal = bootstrap.Modal.getInstance($('#payMethodsModal'));
    const $selectedBankFirstLayer = $('#selected-bank-transfer');

    $selectedBankFirstLayer.html('');
    $selectedBankFirstLayer.append($copyContent);

    $selectedBankFirstLayer.find('.card-body__icon').html($("<img>", {
        src: $this.data('iconSrc'),
        alt: "",
        class: "card-body__icon--img",
    }));
    $selectedBankFirstLayer.find('.card-body__title').html($this.data('name'));
    $selectedBankFirstLayer.attr('data-value', $this.data('value'));

    $('.select-bank-transfer').addClass('d-none');
    $selectedBankFirstLayer.removeClass('d-none');

    setActivePayment($selectedBankFirstLayer);

    modal.hide();
});

function setActivePayment($container) {
    const activeClass = 'is-active';
    const $hidden = $('.pay-method-hidden');
    const $cardToken = $('.tokenize-value');
    const $blikInput = $('.blik_code_input');
    const paymentValue = $container.data('value');

    const $payViaGoogleSubmit = $('.google-pay');
    const $payViaAppleSubmit = $('.apple-pay');
    const $paySubmit = $('.default-pay');

    previousPayMethod = $container.closest('.payment-method-container').find('.card-payment').has('.' + activeClass)?.data('value');

    $container.closest('.payment-method-container')?.find('.card-payment .card-body.' + activeClass)?.removeClass(activeClass);
    $container.find('.card-body').addClass(activeClass);

    $hidden.val(paymentValue).removeClass('is-invalid').trigger('change');

    if (paymentValue === 'blik') {
        $blikInput.attr('required', 'required');
        $blikInput.closest('.blik_code_container').removeClass('d-none');

        $payViaGoogleSubmit.addClass('d-none');
        $payViaAppleSubmit.addClass('d-none');
        $paySubmit.removeClass('d-none');

    } else {
        if (paymentValue === 'c') {
            $cardToken.val($container.data('token'));
        }

        $blikInput.removeAttr('required').removeClass('is-valid').removeClass('is-invalid');
        $blikInput.closest('.blik_code_container').addClass('d-none');
        $blikInput.val('');

        if (paymentValue === 'ap') {
            $payViaGoogleSubmit.removeClass('d-none');
            $payViaAppleSubmit.addClass('d-none');
            $paySubmit.addClass('d-none');
        } else if (paymentValue === 'jp') {
            $payViaGoogleSubmit.addClass('d-none');
            $payViaAppleSubmit.removeClass('d-none');
            $paySubmit.addClass('d-none');
        } else {
            $payViaGoogleSubmit.addClass('d-none');
            $payViaAppleSubmit.addClass('d-none');
            $paySubmit.removeClass('d-none');
        }
    }
}

function setDefaultPayment() {
    const $payMethodHidden = jQuery('.payment-method-container .pay-method-hidden');

    if ($payMethodHidden.length !== 1 || $payMethodHidden.val().trim() === '') {
        return;
    }
    const payMethod = $payMethodHidden.val();
    jQuery('.payment-method-container .selected-credit-card').trigger('click');

    const $container = $payMethodHidden.closest('.payment-method-container');
    const $containerToSelect = $container.find('.card-payment.to-select[data-value="' + payMethod + '"]');

    if ($containerToSelect.length) {
        $containerToSelect.trigger("click");
    }
}

document.addEventListener('hide.bs.modal', function (e) {
    if ($(e.target).attr('id') === 'creditCardFormModal' && previousPayMethod) {
        if ($('input[name$="[payMethod][authorizationToken]"]').val() === "") {
            $('.card-payment[data-value="' + previousPayMethod + '"]').trigger('click');
        }
    }
});
