import {CustomValidator} from "./validator";
import {Spinner} from "./spinner";

$(function () {
    const spinner = new Spinner();

    //hide custom error on address invoice required
    $(document).on('change', '#quick_purchase_invoice', function (e) {
        if (!$(this).is(':checked')) {
            $('#quick_purchase_invoiceAddress').trigger('change');
        }
    })

    $(document).on('submit', '.quick-purchase__form', function (e) {
        e.preventDefault();
        const $form = $(this);

        if (!CustomValidator.formValidation($form.get(0))) {
            return;
        }

        const $buttons = $form.find('button');
        const $submitter = e.submitter ? $(e.submitter) : $form.find(':submit');

        spinner.addSpinner($submitter);
        $buttons.attr('disabled', 'disabled');
        $submitter.attr('disabled', 'disabled');

        $.ajax({
            url: $form.attr('action'),
            method: 'PUT',
            data: $form.serialize(),
            success: (responseJson) => {
                const status = responseJson['success'];
                const redirectUrl = typeof responseJson['redirectUrl'] == 'undefined' || responseJson['redirectUrl'] == '' ? false : responseJson['redirectUrl'];

                if(!redirectUrl) {
                    window.location.reload();
                    return;
                }

                if(status) {
                    window.location.href = responseJson['redirectUrl'];
                }
            },
            error: ({responseJSON}) => {
                CustomValidator.markErrors(responseJSON, $form);
                $form.trigger('interrupted.submit', {submitter: $submitter.get(0)})
                $form.trigger(jQuery.Event('form.invalid'))
            },
        })
    })
});