import {CustomValidator} from "./validator";

export const eMailRegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

$(function () {
    $(document).on('paste input change focusout blur', 'input[type="email"]', function () {
        if (!this.value) {
            return;
        }

        if (eMailRegExp.test(this.value)) {
            this.classList.add('is-valid');
            this.classList.remove('is-invalid');
            this.parentNode.querySelector('.invalid-feedback').innerHTML = '';
        } else {
            CustomValidator.markInvalid(this);
        }
    });
});
