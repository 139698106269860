import jQuery from 'jquery';

const ownedIds = new Promise(resolve => jQuery.ajax('/cart/owned').done(ids => resolve(new Set(ids))));

export function hideBuyButtons() {
    ownedIds.then(ids => {
        jQuery('.add-cart-button,.fast-track-button').each(function () {
            const $this = jQuery(this);
            const data = $this.data();

            if (
                (data['productId'] && ids.has(data['productId'])) &&
                (data['releaseId'] !== "" && data['releaseId'] !== "0" && data['releaseId'] && ids.has(data['releaseId']))
            ) {
                $this.hide();
            }
        });
    });
}
hideBuyButtons();