export class CustomValidator {
    static formValidation(form) {
        let ret = form.checkValidity();
        Array.prototype.filter.call(form.elements, (input) => {
            if(!this.elementValidation(input)) {
                ret = false;
            }
        });
        return ret;
    }

    static elementValidation(element) {
        let ret = element.checkValidity();

        if(!ret) {
            return false;
        }

        const data = element.dataset;
        let message = '';
        const val = element.value;

        if(ret && typeof data.validityVatId !== 'undefined' && val) {
            ret = this.prototype.vatId(val);
            if(!ret) {
                message = data.validityVatId
            }
        }

        if(ret) {
            element.classList.add('is-valid');
        } else {
            element.parentNode.querySelector('.invalid-feedback').innerHTML = message;
            element.classList.add('is-invalid');
        }
        return ret;
    }

    vatId(nip) {
        var weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        nip = nip.replace(/[\s-]/g, '');

        if (nip.length === 10 && parseInt(nip, 10) > 0) {
            var sum = 0;
            for(var i = 0; i < 9; i++){
                sum += nip[i] * weights[i];
            }
            return (sum % 11) === Number(nip[9]);
        }
        return false
    }

    static markErrors(response, $form, addMessage) {
        let errors = response['errors']
        if (typeof errors === 'object' && errors['fields']) {
            const formName = $form.attr('name')

            $.each(errors['fields'], (i, e) => {
                let fieldName = formName;
                i.split('.').forEach(function (nameLabel) {
                    fieldName += '[' + nameLabel + ']';
                });

                let field = $('[name="'+ fieldName+'"]', $form).get(0)
                if (field) {
                    field.setCustomValidity(e)
                    field.checkValidity()
                }
            })
        }
    }

    static markInvalid(element) {
        const $element = $(element);
        const validity = element.validity;
        const data = $element.data();
        let message = '';

        if (data) {
            if (validity.valueMissing && data.validityRequired) {
                message = data.validityRequired;
            } else if (validity.patternMismatch && data.validityPattern) {
                message = data.validityPattern;
            } else if (validity.customError && element.validationMessage) {
                message = element.validationMessage
            } else {
                message = data.validity
            }

            if (message) {
                $element.parent().find('.invalid-feedback').html(message);
            }
        }
        $element.addClass('is-invalid');
    }
}