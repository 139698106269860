import {Toast} from "../../components/toast/js/toast";

$(() => {
    const toast = (prefix, success) => {
        const type = success ? 'success' : 'error';
        new Toast(type, $('#avatar_change_' + prefix + '_' + type).html());
    };

    $(document).on('click', '#avatar_change_delete', function () {
        $.ajax({
            url: $(this).data('url'),
            type: 'DELETE',
        })
            .done(({success, avatar}) => {
                toast('delete', success);
                if (avatar) {
                    $('.profile-avatar-img').attr('src', avatar);
                }
                if (success) {
                    $('#avatar_change_delete').addClass('d-none');
                }
            })
            .fail(() => toast('delete', false));
    });

    $(document).on('click', '#avatar_change_update', function () {
        $('#avatar_change_file').trigger('click');
    });

    $(document).on('change', '#avatar_change_file', function (e) {
        e.preventDefault();
        const $form = $(this).closest('form');
        const data = new FormData($form[0]);
        $form.trigger('reset');

        $.ajax({
            url: $form.attr('action'),
            data,
            type: 'POST',
            contentType: false,
            processData: false,
        })
            .done(({success, errors, avatar}) => {
                toast('update', success);
                errors ??= [];
                $('#avatar_change_update__errors')
                    .toggle(errors.length > 0)
                    .html(errors.map(error => `<div>${error}</div>`).join(''));
                if (avatar) {
                    $('.profile-avatar-img').attr('src', avatar);
                    $('#avatar_change_delete').removeClass('d-none');
                }
            })
            .fail(() => toast('update', false));
    });

});
