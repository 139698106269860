$(function () {
    const inputPassword = $('input[type="password"]');

    inputPassword.on('focus', function (){
        $('.password-validation-box').removeClass('d-none');
    })

    inputPassword.on('keyup blur',function (e){
        $('.password-validation-box .condition').removeClass('is-valid is-invalid');

        const isBlur = e.type === 'blur';
        const $this = $(this);
        $this.removeClass('is-valid is-invalid');
        const pass = $this.val();

        $('.password-validation-box .condition.length').addClass(pass.length >= 8 ? 'is-valid' : (isBlur ? 'is-invalid' : '') );
        $('.password-validation-box .condition.digit').addClass(hasNumber(pass) ? 'is-valid' : (isBlur ? 'is-invalid' : ''));
        $('.password-validation-box .condition.special-char').addClass(hasSpecialChars(pass) ? 'is-valid' : (isBlur ? 'is-invalid' : ''));

        if(isBlur){
            $this.trigger('change');
        }
    })

    function hasNumber(string){
        return /\d/.test(string);
    }

    function hasSpecialChars(string){
        return !(/^[a-zA-Z0-9- ]*$/.test(string))
    }

})