import {debounce} from 'radash'
import {loaderBtn, updateProgress} from './pagination'
import {UserScreen} from './user-screen'
import { Filter } from '../../components/filter/js/partials/_filter';
import {hideBuyButtons} from "./owned";

export class Search {
    clearBtn = $('.search-bar__clear');
    searchInput = $('.search-bar__input');
    searchResults = $('#searchResults');
    moreButton = $('.load-more-search-product-btn');
    emptyBox = $('#search-no-results');
    searchHeader = $('.search-header');
    searchPhrase = $('.search-phrase');
    loader = $('.search-loader');
    paginationBox = $('.pagination-info-box');
    container = $(this.moreButton.data('containerSelector'));
    totalCounter = $('.total-products-counter');
    productsCounter = $('.products-counter');
    searchResultCounter = $('.search-results-counter');

    allProducts = true;
    oldValue = '';
    page = 1;
    computedLimit = 12;
    userScreen = new UserScreen();
    limitMobile = this.userScreen.getLimitMobile();
    limitDesktop = this.userScreen.getLimitDesktop();


    initEvents() {
        this.registerSearchInputKeyUpEvent();
        this.registerClearBtnEvent();
        this.registerMoreBtnEvent();
    }


    sendRequest(value) {
        this.page = parseInt(this.moreButton.data('page'));
        this.allProducts = false;

        const self = this;

        let limitMobile = this.limitMobile;
        if (value.length >= 3) {

            if (value !== this.oldValue) {
                this.loading(true);
                this.page = 1;

            }

            this.oldValue = value;

            let computedLimit = this.userScreen.getComputedLimit();
            let repeat = 1;

            if (self.page > 2) {
                repeat = 0
            }

            const urlParams = new URLSearchParams(location.search);
            urlParams.set('page', self.page);
            urlParams.set('computedLimit', computedLimit);
            urlParams.set('repeat', repeat);

            $.ajax({
                url: '/search/' + value + '?' + urlParams,
            }).done(function (result) {
                self.noresultsBox(self.page === 1 && result.total === 0 || result.total === 0 && !self.emptyBox.hasClass('d-none'));

                if (value !== self.oldValue) {
                    self.clearSearchContainers(false);
                }
                self.totalCounter.text(result.total)
                self.insertProductItemsSearch(result, self.page);
                self.page = parseInt(self.moreButton.data('page'));

                self.searchPhrase.text(value);
                self.searchHeader.removeClass('d-none');

                if (self.page === 2 && computedLimit > limitMobile && repeat === 1 && result.total > 0) {

                    self.page = parseInt(self.moreButton.data('page'));
                    urlParams.set('page', self.page);
                    urlParams.set('computedLimit', limitMobile);
                    urlParams.set('repeat', repeat);

                    $.ajax({
                        url: '/search/' + value + '?' + urlParams,
                    }).done(function (result) {

                        self.insertProductItemsSearch(result, self.page);
                        self.page = parseInt(self.moreButton.data('page'));

                        self.searchPhrase.text(value);
                        self.searchHeader.removeClass('d-none');

                    });
                }
            });


        } else if (value.length === 0) {

            this.page = 1;
            this.clearSearchContainers(true);
            this.loading(true);

        }

    }


    registerSearchInputKeyUpEvent() {
        const self = this;
        const delayConfig = parseInt(this.moreButton.attr('data-delay'));
        self.searchInput.on('keyup', debounce({delay: delayConfig}, (event) => {

            self.clearBtn.toggleClass('invisible', self.searchInput.val().length === 0);
            self.sendRequest(event.target.value);
            Filter.updateFilters('search', event.target.value);
        }));
    }

    registerClearBtnEvent() {
        const self = this;
        self.clearBtn.on('click', function () {
            self.searchInput.val('');
            self.clearBtn.addClass('invisible');
            self.clearSearchContainers(true);

        });
    }

    registerMoreBtnEvent() {
        let userScreen = new UserScreen()
        let addedPage = userScreen.getAddedPage();
        const self = this;
        self.moreButton.on('click', function () {
            loaderBtn(self.moreButton, true, self.container);
            if (self.allProducts) {
                self.page = self.page + addedPage;
                $('.load-more-search-product-btn').data('page', self.page);
                self.loadAllProducts(self.page);
            } else {
                self.searchInput.trigger('keyup');
            }
        })

    }

    loadAllProducts(page) {
        const self = this;

        this.loading(true);
        this.oldValue = '';

        let computedLimit = this.userScreen.getComputedLimit();
        const urlParams = new URLSearchParams(location.search);
        urlParams.set('page', page);
        let repeat = 0;
        if (page === 2 && computedLimit === this.limitDesktop) {
            repeat = 1;
            computedLimit = this.limitMobile;
        }

        if (page === 3 && computedLimit === this.limitDesktop) {
            repeat = 1;
        }

        urlParams.set('computedLimit', computedLimit);
        urlParams.set('repeat', repeat);

        $.ajax({
            url: '/product/all_products?' + urlParams,
        }).done(function (result) {

            self.insertProductItems(result, page);
            self.noresultsBox(false);
        });
    }

    insertProductsItemsToContainer(container, items, page) {
        const row = container.find('.row[id^=grid-list]');
        if (page > 1) {
            row.append(items);
        } else {
            row.html(items);
        }
        this.updateProductsCount(container);
        hideBuyButtons();
    }

    insertProductItems(result, page) {

        this.insertProductsItemsToContainer(this.searchResults, result.products.content, page);
        this.loading(false);
        updateProgress(this.paginationBox, result.count, result.total, page === 1)
        if (result.more) {
            this.moreButton.removeClass('d-none');
            page++;
            this.moreButton.data('page', page);

        } else {
            this.moreButton.addClass('d-none');
        }
        loaderBtn(this.moreButton, false, this.container);
    }

    insertProductItemsSearch(result, page) {

        this.insertProductsItemsToContainer(this.searchResults, result.products.content, page);
        this.loading(false);
        updateProgress(this.paginationBox, result.count, result.total, page === 1)
        let addedPage = 1;

        addedPage = this.userScreen.getAddedPage();
        if (page < 3) {
            addedPage = 1;
        }
        page = page + addedPage;

        this.moreButton.data('page', page);
        if (result.more) {
            this.moreButton.removeClass('d-none');
        } else {
            this.moreButton.addClass('d-none');
        }
        loaderBtn(this.moreButton, false, this.container);
    }

    clearSearchContainers(loadAll) {

        this.loading(true);
        this.moreButton.addClass('d-none');
        this.moreButton.data('page', 1);
        if (!this.allProducts && loadAll) {

            this.allProducts = true;
            this.page = 1;
            this.searchHeader.addClass('d-none');
            this.loadAllProducts(this.page);
        }

        let computedLimit = this.userScreen.getComputedLimit();

        if (computedLimit > this.limitMobile) {
            let delayConfig = parseInt(this.moreButton.attr('data-delay'))
            setTimeout(() => {
                this.loadAllProducts(2);
            }, delayConfig);
        }

    }

    noresultsBox(show) {
        if (show) {
            this.emptyBox.removeClass('d-none');
            this.searchResults.addClass('d-none');
            this.paginationBox.addClass('d-none');
        } else {
            this.emptyBox.addClass('d-none');
            this.searchResults.removeClass('d-none');
            this.paginationBox.removeClass('d-none');
        }
    }

    updateProductsCount(container) {
        let span = container.find('.search-results-counter');

        let count = this.totalCounter.html();

        span.html('(' + count + ')');

    }

    loading(show) {
        if (show) {
            this.loader.removeClass('d-none');
        } else {
            this.loader.addClass('d-none');
        }

    }

    refreshProductsList(refreshPage) {

        const $this = $('.load-more-search-product-btn');
        const page = parseInt($this.data('page'));
        let userScreen = new UserScreen();
        let computedLimit = userScreen.getComputedLimit();

        if (page === refreshPage && computedLimit === userScreen.getLimitDesktop()) { //only for desktop
            $this.data('repeat', refreshPage);
            $this.data('page', refreshPage++);
            const search = new Search();
            search.loadAllProducts(refreshPage++);
        }
    }
}


document.addEventListener("readystatechange", (event) => {
    const search = new Search();
    search.refreshProductsList(1);

});
$(function () {
    const search = new Search();
    search.initEvents();
});