import {CustomValidator} from "./validator";

$(() => {
    let isPasswordValid = false;

    $(document).on('click', '#account_delete_submit', function (e) {
        CustomValidator.elementValidation($(this).closest('form').find('.password-validator').get(0));

        if (!isPasswordValid) {
            e.preventDefault();
            e.stopImmediatePropagation();
            return false;
        }
    });

    $(document).on('password-validated', '.password-validator', function (e, valid) {
        isPasswordValid = valid;
    });
});
