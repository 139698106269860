const containersMultiRange = document.getElementsByClassName('form-multi-range-container');

const slideMultiRange = (element) => {
    const inputTextRangeSelector = element.dataset?.inputTextId;

    if(!inputTextRangeSelector){
        return;
    }

    const inputTextRange = document.getElementById(inputTextRangeSelector);
    inputTextRange.value = element.value;
}

const multiRangeInputListener = (min, max, current) => {
    if(parseFloat(max.value) <= parseFloat(min.value)) {
        if(current.classList.contains('form-multi-range-min')) {
            min.value = max.value;
        } else {
            max.value = min.value;
        }
    }

    slideMultiRange(current);
    return true;
}

const textInputRangeListener = (textInputRange, container, min, max) => {
    const currentId = textInputRange.id;
    const range = container.querySelector('[data-input-text-id = "'+currentId+'"]');

    if(parseFloat(max.value) <= parseFloat(min.value)) {
        if(currentId === min.id) {
            min.value = max.value;
        } else {
            max.value = min.value;
        }
    }

    range.value = textInputRange.value;
}

Array.prototype.filter.call(containersMultiRange, (container) => {
    const min = container.getElementsByClassName('form-multi-range-min');
    const max = container.getElementsByClassName('form-multi-range-max');

    if(min.length === 0 || max.length === 0){
        return;
    }

    [min[0], max[0]].forEach(element => {
        element.addEventListener('input', (e) => {
            return multiRangeInputListener(min[0], max[0], e.target);
        });
    });

    const inputsText = {};

    if(min[0].dataset.inputTextId) {
        inputsText.min = document.getElementById(min[0].dataset.inputTextId);
    }

    if(max[0].dataset.inputTextId) {
        inputsText.max = document.getElementById(max[0].dataset.inputTextId);
    }

    for(const key in inputsText) {
        const inputText = inputsText[key];

        if(!inputText) {
            continue;
        }

        inputText.addEventListener('change', (e) => {
            textInputRangeListener(e.target, container, inputsText.min, inputsText.max);
        });
    }


})