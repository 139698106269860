const cookiesWraper = document.getElementById('cookie-box')
const cookiesAction = document.getElementById('cookies-accept')

if (cookiesAction) {
    cookiesAction.addEventListener('click', e => {
        e.preventDefault()
        cookiesWraper.classList.add('d-none')
        localStorage.setItem('cookiesAccepted', true)
    })
}

if (cookiesWraper) {
    if(localStorage.getItem('cookiesAccepted') === null) {
        cookiesWraper.classList.remove('d-none')
    }
}