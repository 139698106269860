import * as bootstrap from "bootstrap";

import '@fortawesome/fontawesome-free/js/all'
import IMask from "imask";

// Import ALL images
const imagesCtx = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesCtx.keys().forEach(imagesCtx);

import './components/ajax-request'
import './components/validation'
import './components/fields'

import '../components/carousel/js/carousel'
import '../components/filter/js/filter'
import '../components/newsletter/js/newsletter'
import './components/address-subform'
import './components/recaptcha'

import './components/cart'
import './components/nav'
import './components/cookie'
import './components/error'
import './components/collapse'
import './components/filter'
import './components/profile-address'
import './components/profile-avatar'
import './components/profile-email'
import './components/profile-order'
import './components/profile-payment'
import './components/profile-agreements'
import './components/profile-delete'
import './components/password-validator'
import './components/search'
import './components/modal'
import './components/multi-range'
import './components/pagination'
import './components/product'
import './components/pay-methods'
import './components/quick-purchase'
import './components/scroll'
import './components/tooltip'
import './components/password_validation'
import './components/user-screen'
import '../components/toast/js/toast'
import './components/email-validator'


import './components/owned'

import './components/e-kiosk-player'
