//Should be part of pagination so that limits and breakpoint can be configurable per list
export class UserScreen {

    mobileBreakpointWidth = 720;
    limitMobile = typeof page_count_mobile !== 'undefined' ? page_count_mobile : 12;
    limitDesktop = 2 * this.limitMobile;

    getAddedPage() {
        return this.isMobileView() ? 1 : 2
    }

    getComputedLimit() {
        return this.isMobileView() ? this.getLimitMobile() : this.getLimitDesktop()
    }

    getLimitDesktop(){
        return this.limitDesktop
    }

    getLimitMobile(){
        return this.limitMobile
    }

    isMobileView() {
        return window.innerWidth < this.mobileBreakpointWidth;
    }
}