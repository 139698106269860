import "regenerator-runtime/runtime.js";
import {Spinner} from "./spinner";
import {Toast} from "../../components/toast/js/toast";
import * as bootstrap from 'bootstrap';
import {AddressSubform} from './address-subform';
import {CustomValidator} from "./validator";
import {EditAddress} from './profile-address'
import {initIMask} from './validation'
import PerfectScrollbar from "perfect-scrollbar";
import {SwiperCarousel} from '../../components/carousel/js/swiper/_carousel'

const checkPaymentTime = 3000;

window.updateCartCountBadge = (value) => {

    const cartCount = $('.cart-count');
    const handler = function(count){
        cartCount.text(+count > 0 ? count.toString() : '');
        +count > 0 ? cartCount.parent().removeClass('d-none') : '';
    }

    if (value === '' || /^\d+$/.test(value)) {
        handler(value);
    } else {
        $.ajax({
            url: '/cart/count',
            success: ({count}) => handler(count),
        });
    }
};
$(() => {
    updateCartCountBadge();
    AddressSubform.init($('.address-subform'), {toggleForm: true});

    jQuery(document).on('submit', '.cart-addresses form', function (e) {
        e.preventDefault();
        const formUrl = '/customer/save-address';
        const $this = $(this);
        $.ajax({
            url: formUrl,
            data: $this.serialize(),
            method: 'POST',
            success: function (response) {
                let newBlock = $(response.address);
                $('#customerAddressesModal .data-block__content .invoice-address:last').before(newBlock);
                $this.find('button').removeAttr('disabled');
                const spinner = new Spinner();
                spinner.removeSpinner($this);
                $this.find('.hide-form').trigger('click');
                newBlock.find('.address').trigger('click');
                $this.trigger('reset');
                if (typeof response.toastMessage !== 'undefined' && response.toastMessage) {
                    new Toast(response.status ? 'success' : 'error', response.toastMessage);
                }
            },
            error: function () {
                $this.removeAttr('disabled');
                new Toast('error', translations['error-message']);
            },
        });
    });

    jQuery(document).on('submit', '.coupon-form', function (e) {
        e.preventDefault();
        const $form = $(this);

        $.ajax({
            url: '/cart/voucher-code',
            data: $form.serialize(),
            method: 'POST',
            success: function (response) {
                window.location.reload();
            },
            error: function (response) {
                CustomValidator.markErrors(response.responseJSON, $form);
                $form.trigger('interrupted.submit', {submitter: e.submitter})
                $form.trigger(jQuery.Event('form.invalid'))
            }
        });
    })

    let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const mobileWindowWidth = 976;

    if(windowWidth < mobileWindowWidth)
    {
        $('.cart__checkout:not(.cart__checkout-mobile) .blik_code_container').remove();
    }else{
        $('.cart__checkout.cart__checkout-mobile .blik_code_container').remove();
    }
});

jQuery(document).on('click', '.add-cart-button, .add-cart-link', function (e) {
    e.preventDefault();
    const $this = $(this);
    const urlAjax = $this.data('url');
    const token = $this.data('token');
    const isLink = $this.hasClass('add-cart-link');

    if (!urlAjax || !token) {
        return;
    }

    const spinner = new Spinner();

    if (!isLink) {
        spinner.addSpinner($this);
    }
    $this.attr('disabled', 'disabled');

    const modal = bootstrap.Modal.getOrCreateInstance($('#cartAddItemModal'));
    const mutation = $this.data('mutation');
    const release = $this.data('release');
    const fromNextRelease = $this.data('subscription-start') === 'awaiting' ? 1 : 0;

    $.ajax({
        url: urlAjax,
        method: 'POST',
        data: {
            'token': token,
            'mutation': mutation,
            'release': release,
            'from-next-release': fromNextRelease
        },
        success: function (response) {
            if (typeof response.redirectTo !== 'undefined' && response.redirectTo !== '') {
                window.location.href = response.redirectTo;
                return;
            }
            if (response.type === 'success' && typeof response.itemsCount != 'undefined') {
                window.updateCartCountBadge(response.itemsCount);
            }

            if ($this.closest('.cart--step-1').length > 0) {
                return window.location.reload();
            }

            if($(window).width() <= 720){
                window.location.href = '/koszyk';
                return ;
            }

            if (response.type === 'success') {
                $('#cartAddItemModal .cart-item-content').html(response.content)

                $('#cartAddItemModal .suggested-products-wrapper').html(response.suggestedProducts);
                SwiperCarousel.init($('#cartAddItemModal .suggested-products-wrapper .carousel'));
                modal.show();
            } else {
                new Toast(response.type, response.message)
            }

        },
        error: function () {
            new Toast('error', translations['app.cart.add.error'])
        },
        complete: function () {
            if (!isLink) {
                spinner.removeSpinner($this);
            }
            $this.removeAttr('disabled');
        },
    });
});


jQuery(document).on('click', '.product-delete', function (e) {
    const $this = $(this);
    const urlAjax = $this.data('url');

    const spinner = new Spinner();
    spinner.addSpinner($this);
    $this.attr('disabled', 'disabled');

    $.ajax({
        url: urlAjax,
        success: function (response) {
            window.location.reload();
        },
        error: function () {
            spinner.removeSpinner($this);
            $this.removeAttr('disabled');
        },
    });
});


jQuery(document).on('submit', '#creditCardForm', function (e) {
    e.preventDefault();
});

jQuery(function () {
    const $paymentWait = $('.processing-payment-wait');

    if (!$paymentWait.length) {
        return;
    }

    const url = $paymentWait.data('url');
    const idOrder = $paymentWait.data('idOrder');

    if (!url || !idOrder) {
        return false;
    }

    const interId = setInterval(function () {
        $.ajax({
            url,
            data: {idOrder},
            success(response) {
                if (response.status === 'completed') {
                    clearInterval(interId);
                    window.location.href = response.url;
                }
            },
        });
    }, checkPaymentTime);
});

jQuery(document).on('click', '.cart-addresses .addresses-block-header__btn >*', function () {
    $(this).parent().trigger('click');
});

jQuery(document).ready(function () {
    const cart_invoice = $('#cart_invoice');
    cart_invoice.on('change', function () {
        cartInvoiceInputHandler(cart_invoice);
    });
    cart_invoice.trigger('change');
});


function cartInvoiceInputHandler(elem) {
    const wrapper = $('.cart-invoice-btn-edit,.invoice-data-wrapper');
    if (elem.prop('checked')) {
        wrapper.removeClass('d-none');
    } else {
        wrapper.addClass('d-none');
    }
    const url = '/session/save-item';
    $.ajax({
        url: url,
        data: JSON.stringify({'needInvoice': elem.prop('checked')}),
        method: 'POST',
        success: function (response) {

        },
        error: function () {

        },
    });

}


jQuery(document).on('click', '.cart-addresses .address:not(.address--add)', function (e) {
    const $this = $(this);
    const $invoiceWrapper = $('.invoice-data-wrapper');
    const $chosenAddressHTML = $this.prop('innerHTML');

    if ($('#cart_invoiceAddress').length) {
        $('#cart_invoiceAddress').val($this.parent().data('id'));
    } else {
        $('#quick_purchase_invoiceAddress').val($this.parent().data('id')).trigger('change');
    }

    $invoiceWrapper.find('.address-container').html($chosenAddressHTML);

    $('.address.active').removeClass('active');
    $this.addClass('active');

    const modal = bootstrap.Modal.getInstance($('#customerAddressesModal'));
    modal.hide();
});

const createCardForm = async (numSel, expSel, ccvSel) => {
    const payuSdkForms = PayU(process.env.PAYU_POS || payu_pos_id, {dev: process.env.PAYU_DEV });
    const secureForms = payuSdkForms.secureForms();

    const inputNumber = secureForms.add('number', {
        cardIcon: false, style: {basic: {fontSize: '20px'}}, placeholder: {number: ''}, lang: 'pl',
    }).render(numSel);

    const inputDate = secureForms.add('date', {
        style: {basic: {fontSize: '20px'}}, placeholder: {date: ''}, lang: 'pl',
    }).render(expSel);

    const inputCVV = secureForms.add('cvv', {
        style: {basic: {fontSize: '20px'}}, placeholder: {cvv: ''}, lang: 'pl',
    }).render(ccvSel);

    return {
        tokenize: (onSuccess = null, onError = null, isMulti = false) => payuSdkForms
            .tokenize(isMulti ? 'MULTI' : 'SINGLE')
            .then(result => {
                if (result.status === 'SUCCESS') {
                    if (onSuccess) {
                        onSuccess(result.body);
                    }

                    return result.body;
                }

                if (onError) {
                    onError(result.error);
                }

                return result.error;
            }),
        clear: () => {
            inputNumber.clear();
            inputDate.clear();
            inputCVV.clear();
        },
        remove: () => {
            inputNumber.remove();
            inputDate.remove();
            inputCVV.remove();
        },
    };
};

function openQuickPurchaseModal(productId, mutationId = 0, releaseId = 0, fromNextRelease = 0) {
    if (productId <= 0) return;

    const $wrapper = (el => !el.length ? jQuery('<div id="quick-purchase-wrapper">').hide().appendTo('body') : el)(jQuery('#quick-purchase-wrapper'));

    const url = new URL(`/cart/quick-purchase-modal/${productId}`, window.location.origin);

    if (mutationId > 0) {
        url.searchParams.append('mutation', new String(mutationId));
    }

    if (releaseId > 0) {
        url.searchParams.append('release', new String(releaseId));
    }

    if (fromNextRelease) {
        url.searchParams.append('from-next-release', new String(fromNextRelease));
    }

    $.ajax({
        url: url,
        async success(response) {
            const $modal = jQuery('#quickPurchaseModal');
            if ($modal.length > 0) {
                bootstrap.Modal.getOrCreateInstance($modal).hide();
            }
            $wrapper.html('').hide();

            $wrapper.html(response).show();
            bootstrap.Modal.getOrCreateInstance($wrapper.find('#quickPurchaseModal')).show();
            $('.needs-validation', $wrapper).trigger('form.loaded');
            $wrapper.find('.selectize').selectize(window.selectize_options);
            $wrapper.find('.selectize-dropdown-content').each(function () {
                this.perfectScrollbar = new PerfectScrollbar(this, {suppressScrollX: true})
                observer.observe(this, {
                    childList: true,
                });
            });

            initIMask($wrapper);
            const $addresses = $wrapper.find('.addresses-block');
            if ($addresses.length > 0) {
                new EditAddress($addresses)
            }
            configureSecureForm($wrapper.find('.card-form-init'));

            $('.product__formats--option:not(:checked)', $wrapper).on('change', function (e) {
                openQuickPurchaseModal($(this).val(), mutationId, releaseId)
            });
            $('#quickPurchaseModal').trigger('quickPurchase.opened');
        },
    });
}

jQuery(document).on('click', '.fast-track-button,.quick-purchase-wrapper .product-variant-option', function (e) {
    e.preventDefault();

    const id = parseInt($(this).data('product-id') ?? '0');
    const mutationId = parseInt($(this).data('mutation-id') ?? '0');
    const releaseId = parseInt($(this).data('release-id') ?? '0');
    const fromNextRelease = $(this).data('subscription-start') === 'awaiting' ? 1 : 0;

    openQuickPurchaseModal(id, mutationId, releaseId, fromNextRelease);
});
$(document).on('click', '.quick-purchase-wrapper .btn-check-box.row', function (e) {
    e.preventDefault();
    const $option = $(this).find('.product-variant-option');
    if (!$option.prop('checked')) {
        $option.prop('checked', true).trigger('click');
    }

})

$(document).on('change', '.quick-purchase-wrapper .product-variant-select', function (e) {
    e.preventDefault();
    const s = this.selectize;
    const id = parseInt(s.options[s.items[0]].product ?? '0');
    const mutationId = parseInt(s.options[s.items[0]].mutation ?? '0');
    const releaseId = parseInt(s.options[s.items[0]].release ?? '0');
    openQuickPurchaseModal(id, mutationId, releaseId);
})

jQuery(function () {
    jQuery(document)
        .on('tokenize-success', '.card-form-init .tokenize-wrapper', function () {
            jQuery(this).siblings('.invalid-feedback').hide().text('');
        })
        .on('tokenize-error', '.card-form-init .tokenize-wrapper', function (e, message) {
            jQuery(this).siblings('.invalid-feedback').show().text(message);
        })
        .on('tokenize-success', '.card-form-init', function (e, token) {
            jQuery('.tokenize-value').val(token);
        })
        .on('tokenize-error', '.card-form-init', function () {
            jQuery('.tokenize-value').val('');
        });
});

jQuery(document).on('click', '.payment-method-container.card-tokens .to-select.add-card', function () {
    jQuery(this).parents('.modal-body').find('.card-form-wrapper').removeClass('d-none');
});

jQuery(document).on('click', '.payment-method-container.card-tokens .to-select.select-card', function () {
    const $this = jQuery(this);

    $this.parents('.modal-body').find('.card-form-wrapper').addClass('d-none');

    const modalId = jQuery('.payment-method-container.card-tokens').data('modal-id');

    if (modalId) {
        const modal = document.getElementById(modalId);

        if (modal) {
            bootstrap.Modal.getOrCreateInstance(modal).hide();
        }
    }

    jQuery('.credit-card').addClass('selected-credit-card').trigger('click').find('.pay-icon-img').addClass('max-height-initial');

    const $container = jQuery('.payment-method-container.first-layer');

    if (!$container.length) return;

    $container.find('.credit-card-expiry').text($this.find('.token-expiry').text());
    $container.find('.credit-card-masked').text($this.find('.token-masked').text());

    const $iconContainer = $container.find('.credit-card .pay-icon-container');

    if (!$iconContainer.length) return;

    $iconContainer.find('img').removeClass('d-none').attr('src', $this.data('icon-src'));
    $iconContainer.find('i').addClass('d-none');
});


/**
 * TODO make it into a class instead
 * @param {jQuery} $config
 * @param {function|null} onInit
 */
function configureSecureForm($config, onInit = null) {
    const jQ = selector => jQuery(selector || []);

    const [numCardSel, expCardSel, cvvCardSel] = [
        $config.data('card-num'),
        $config.data('card-exp'),
        $config.data('card-cvv'),
    ];

    const [$tokenize, $remove, $clear, $multi, $masked, $expiry, $save] = [
        jQ($config.data('tokenize')),
        jQ($config.data('remove')),
        jQ($config.data('clear')),
        jQ($config.data('multi')),
        jQ($config.data('masked')),
        jQ($config.data('expiry')),
        jQ($config.data('save')),
    ];

    if (!numCardSel || !expCardSel || !cvvCardSel || !$tokenize.length) {
        return;
    }

    /** @type {Array<string, jQuery>} **/
    const $form = {
        number: jQ(numCardSel),
        date: jQ(expCardSel),
        cvv: jQ(cvvCardSel),
    };

    createCardForm(numCardSel, expCardSel, cvvCardSel).then(handler => {
        if (typeof onInit === 'function') {
            onInit(handler);
        }

        const modalElement = document.getElementById($config.data('modal'));
        const modalInstance = bootstrap.Modal.getOrCreateInstance(modalElement);

        const clearForm = () => {
            handler.clear();
            $multi.prop('checked', false);
        };

        modalElement?.addEventListener('hidden.bs.modal', clearForm);

        $remove.on('click', () => handler.remove());
        $clear.on('click', clearForm);

        $multi.on('click', () => $save.prop('checked', $multi.prop('checked')));

        $tokenize.on('click', () => {

            $form.number.trigger('tokenize-success');
            $form.date.trigger('tokenize-success');
            $form.cvv.trigger('tokenize-success');

            handler.tokenize(
                ({token, mask}) => {
                    $config.trigger('tokenize-success', token);
                    $masked.text(mask);
                    $expiry.text('');
                    modalInstance?.hide();

                    const $creditCard = jQuery('.credit-card');

                    $creditCard.removeClass('selected-credit-card');
                    $creditCard.parent().find('.credit-card__change-card-link').removeClass('d-none');

                    const $iconContainer = jQuery('.credit-card .pay-icon-container');

                    if (!$iconContainer.length) return;

                    $iconContainer.find('img').addClass('d-none').removeClass('max-height-initial');
                    $iconContainer.find('i').removeClass('d-none');

                },
                ({messages}) => {

                    $config.trigger('tokenize-error');
                    messages
                        .filter(({type}) => type === 'validation')
                        .forEach(({source, message}) => {
                            $form[source].trigger('tokenize-error', message);
                        });
                },
                $multi.prop('checked'),
            );
        });
    });
}

jQuery(async function () {
    jQuery('.card-form-init').each(function () {
        configureSecureForm($(this));
    });
});

jQuery(document).on('change', '#cart_guest_email:not(.no_customer_check)', function () {
    const $this = $(this);
    const email = $this.val();
    const token = $('#guest_email_token').val();
    if (token) {
        $.ajax({
            url: '/customer/email-exists',
            data: {'form[email]': email, 'form[_token]': token},
            method: 'POST',
            success: function (response) {
                if (response.exists) {
                    location.href = '/koszyk/haslo';
                }
            },
            error: function () {
            },
        });
    }

});

jQuery(document).on('change', '.has-voucher', function () {
    if ($(this).is(':checked')) {
        $('.cart__checkout__coupon-checkbox').addClass('d-none');
        $('.cart__checkout__coupon-code').removeClass('d-none');
    }
});

jQuery(document).on('click', '.btn-voucher-code', function (e) {
    e.preventDefault();

    $.ajax({
        url: '/cart/voucher-code-remove',
        data: {'voucher': $(this).data('voucher')},
        method: 'POST',
        success: function (response) {
            window.location.reload();
        },
        error: function (response) {

        }
    });
})
